import React, { Component } from 'react'
import { Button, Card, message, Form, Input, Row, Col, Upload } from 'antd'
import './OrganSetting.less'
import axios from 'axios';
import baseConfig from '../../config/BaseConfig';
import { PlusOutlined, LoadingOutlined } from '@ant-design/icons';

// 
function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
}

const organInfoLayout = {
    labelCol: {
        span: 4,
    },
    wrapperCol: {
        span: 16,
    },
};

const roleNameLayout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 16,
    },
};

/* 机构设置 */
export default class OrganSetting extends Component {
    organInfoRef = React.createRef();
    organLogoRef = React.createRef();
    roleNameRef = React.createRef();

    state = {
        organInfoEditType: false,
        roleInfoEditType: true,
        organName: '',
        organAbbreviation: '',
        organAddress: '',
        organUrl: '',
        organPhone: '',
        organType: '',
        operatorName: '',
        assistantName: '',
        testerName: '',
        roleNameEdit: 'inline-block',
        roleNameSave: 'none',
        fileList: [],
        uploading: false,
        loading: false,
        imageUrl: ''
    }


    componentDidMount() {
        this.organInfoData();
    }

    // 表单初始项-机构信息
    organInfoFormData = [
        {
            title: '机构名称',
            inputType: 'input',
            seacrhType: 'group_name',
            value: '',
            rules: []
        },
        {
            title: '机构简称',
            inputType: 'input',
            seacrhType: 'abbreviation',
            value: '',
            rules: []
        },
        {
            title: '地\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0址',
            inputType: 'input',
            seacrhType: 'address',
            value: '',
            rules: []
        },
        {
            title: '网\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0址',
            inputType: 'input',
            seacrhType: 'domain',
            value: '',
            rules: []
        },
        {
            title: '联系电话',
            inputType: 'input',
            seacrhType: 'phone',
            value: '',
            rules: [
                // {
                //     required: true,
                //     message: '请输入联系电话',
                // },
                // {
                //     pattern: new RegExp(/^1\d{10}$/, "g"),
                //     message: '输入联系电话格式不正确',
                // },
            ]
        },
    ]

    roleNameFormData = [
        {
            title: '机构类型',
            inputType: 'input',
            seacrhType: 'org_type_id',
            value: '',
            rules: []
        },
        {
            title: '操作员角色名称',
            inputType: 'input',
            seacrhType: 'operator_name',
            value: '',
            rules: []
        },
        {
            title: '助理角色名称',
            inputType: 'input',
            seacrhType: 'assistant_name',
            value: '',
            rules: []
        },
        {
            title: '受测者角色名称',
            inputType: 'input',
            seacrhType: 'tester_name',
            value: '',
            rules: []
        }
    ]

    // 获取机构信息
    organInfoData = () => {
        axios.get(baseConfig.httpUrl + '/api/group/show-group').then(
            response => {
                if (this.organInfoRef.current) {
                    if (response.data.res === 1) {
                        this.organInfoRef.current.setFieldsValue({
                            abbreviation: response.data.abbreviation,
                            group_name: response.data.group_name,
                            address: response.data.address,
                            domain: response.data.domain,
                            phone: response.data.phone,
                        });
                        this.setState({
                            organName: response.data.group_name,
                            organAbbreviation: response.data.abbreviation,
                            organAddress: response.data.address,
                            organUrl: response.data.domain,
                            organPhone: response.data.phone,
                            imageUrl: response.data.logo_url,
                        });
                    }
                    else {
                        message.error(response.data.msg);
                    }
                }
            }
        )

        axios.get(baseConfig.httpUrl + '/api/group/show-group-role').then(
            response => {
                if (this.roleNameRef.current) {
                    if (response.data.res === 1) {
                        this.roleNameRef.current.setFieldsValue({
                            org_type_id: response.data.org_type_id,
                            operator_name: response.data.operator_name,
                            assistant_name: response.data.assistant_name,
                            tester_name: response.data.tester_name,
                        });

                        this.setState({
                            organType: response.data.org_type_id,
                            operatorName: response.data.operator_name,
                            assistantName: response.data.assistant_name,
                            testerName: response.data.tester_name,
                        });
                    }
                    else {
                        message.error(response.data.msg);
                    }
                }

            }
        )
    }

    // 创建表单
    initFrom = (formData, formType) => {
        const children = [];
        for (let i = 0; i < formData.length; i++) {
            children.push(
                <Col span={12} key={i}>
                    <Form.Item
                        name={formData[i].seacrhType}
                        label={formData[i].title}
                        rules={formData[i].rules}
                    >
                        {this.createFromItem(formData[i], formType)}
                    </Form.Item>
                </Col>,
            );
        }

        return children;
    };

    // 创建表单item
    createFromItem = (itemData, formType) => {
        if (formType === 'organInfo') {
            return <Input placeholder={'请输入' + itemData.title.replace(/\s*/g, "")} disabled={this.state.organInfoEditType} />
        }
        else if (formType === 'roleName') {
            if (itemData.seacrhType === 'org_type_id') {
                return <Input disabled={true} />
            }
            else {
                return <Input placeholder={'请输入' + itemData.title.replace(/\s*/g, "")} disabled={this.state.roleInfoEditType} />
            }
        }

    }

    // 保存机构信息
    saveOrganInfo = (values) => {
        axios.post(baseConfig.httpUrl + '/api/group/edit-group', values).then(
            response => {
                if (response.data.res === 1) {
                    message.success(response.data.msg)
                } else {
                    message.error(response.data.msg)
                }
            }
        )
    }

    // 角色名称定义-保存
    saveRoleName = (values) => {
        axios.post(baseConfig.httpUrl + '/api/group/edit-group-role', values).then(
            response => {
                if (response.data.res === 1) {
                    this.setState({
                        roleNameEdit: 'inline-block',
                        roleNameSave: 'none',
                        roleInfoEditType: true
                    })
                    message.success(response.data.msg)
                } else {
                    message.error(response.data.msg)
                }
            }
        )
    }

    // 角色名称定义-编辑
    roleNameEditFun = () => {
        this.setState({
            roleNameEdit: 'none',
            roleNameSave: 'inline-block',
            roleInfoEditType: false
        })
    }

    // 保存头像
    handleUpload = () => {
        const { fileList } = this.state;
        const formData = new FormData();
        fileList.forEach(file => {
            formData.append('image', file);
        });

        this.setState({
            uploading: true,
        });

        axios.post(baseConfig.httpUrl + '/api/group/upload-logo', formData).then(
            response => {
                if (response.data.res === 1) {
                    this.setState({
                        fileList: [],
                        uploading: false,
                    });
                    message.success('logo保存成功');
                }
                else {
                    this.setState({
                        uploading: false,
                    });
                    message.error(response.data.msg);
                }
            }
        )
    };

    // 添加图片
    handleChange = info => {
        if (info.file.status === 'uploading') {
            this.setState({ loading: true });
            return;
        }
        if (info.file.status === 'done') {
            // Get this url from response in real world.
            getBase64(info.file.originFileObj, imageUrl =>
                this.setState({
                    imageUrl,
                    loading: false,
                }),
            );
        }
    };

    render() {
        const { uploading, fileList, loading, imageUrl } = this.state;
        const props = {
            beforeUpload: file => {
                this.setState(state => ({
                    fileList: [...state.fileList, file],
                }));
                return true;
            },
            fileList,
        };

        const uploadButton = (
            <div>
                {loading ? <LoadingOutlined /> : <PlusOutlined />}
                <div style={{ marginTop: 8 }}>选择图片</div>
            </div>
        );

        return (
            <Card title="" className='organSetting'>

                {/* 机构信息 */}
                <div className="setting-module">
                    <div className='module-header'>
                        <div className='module-header-colorBlock'></div>
                        <div className='module-header-title'>机构信息</div>
                    </div>
                    <Form
                        {...organInfoLayout}
                        ref={this.organInfoRef}
                        name="organ-Info"
                        onFinish={this.saveOrganInfo}
                        className='module-form'
                        initialValues={{
                            abbreviation: this.state.organAbbreviation,
                            group_name: this.state.organName,
                            address: this.state.organAddress,
                            domain: this.state.organUrl,
                            phone: this.state.organPhone,
                        }}
                    >
                        <Row>
                            {this.initFrom(this.organInfoFormData, 'organInfo')}
                        </Row>
                        <Row>
                            <Col span={6} offset={2} style={{ textAlign: 'left' }}>
                                <Button type="primary" htmlType="submit" className='btn-fillets' >保存机构信息</Button>
                            </Col>
                        </Row>
                    </Form>
                </div>

                {/* 机构logo */}
                <div className="setting-module" style={{ width: '50%' }}>
                    <div className='module-header'>
                        <div className='module-header-colorBlock'></div>
                        <div className='module-header-title'>机构logo</div>
                    </div>
                    <div className='module-body'>
                        <div className='module-body-left'>
                            <Upload
                                {...props}
                                listType="picture-card"
                                className="avatar-uploader module-body-upload"
                                action=''
                                showUploadList={false}
                                onChange={this.handleChange}
                            >
                                {imageUrl ? <img src={imageUrl} alt="上传图片" style={{ width: '100%' }} /> : uploadButton}
                            </Upload>
                        </div>

                        <div className='module-body-right'>

                            <p style={{ marginTop: 50 }}>本地上传图片：</p>
                            <p style={{ marginBottom: 5 }}>您需传一张机构的logo图片，点击图片选择机构logo</p>
                            <p style={{ margin: 0 }}>（文件小于2M，支持图片格式为jpg，png）</p>

                            <Button
                                type="primary"
                                className='btn-fillets'
                                onClick={this.handleUpload}
                                disabled={fileList.length === 0}
                                loading={uploading}
                                style={{ marginTop: 10 }}
                            >
                                {uploading ? '上传中' : '\xa0\xa0保存\xa0\xa0'}
                            </Button>
                        </div>

                    </div>
                </div>

                {/* 角色名称定义 */}
                <div className="setting-module" style={{ width: '50%' }}>
                    <div className='module-header'>
                        <div className='module-header-colorBlock'></div>
                        <div className='module-header-title'>角色名称定义</div>
                    </div>
                    <Form
                        {...roleNameLayout}
                        ref={this.roleNameRef}
                        name="role-name"
                        onFinish={this.saveRoleName}
                        className='module-form'
                        initialValues={{
                            org_type_id: this.state.organUrl,
                            operator_name: this.state.operatorName,
                            assistant_name: this.state.assistantName,
                            tester_name: this.state.testerName,
                        }}
                    >
                        <Row >
                            {this.initFrom(this.roleNameFormData, 'roleName')}
                        </Row>
                        <Row >
                            <Col span={24} offset={4} style={{ textAlign: 'left' }}>
                                <Button type="primary" htmlType="button" className='btn-fillets' style={{ display: this.state.roleNameEdit }} onClick={this.roleNameEditFun} >编辑</Button>
                                <Button type="primary" htmlType="submit" className='btn-fillets' style={{ display: this.state.roleNameSave }} >保存</Button>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </Card >
        )
    }
}
