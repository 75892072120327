import React, { Component } from 'react';
import { Card } from 'antd';
import { connect } from 'react-redux';
import baseConfig from '../../config/BaseConfig';
import './DataBoard.less';

/* 账户充值 */
class DataBoard extends Component {

    componentDidMount() {

    }

    render() {
        
        const loginType = this.props.loginData
        
        return (
            <Card className='databoard' style={{}}>

                <iframe src={baseConfig.httpUrl + '/api/kanban/index?type=1&t=' + new Date().getTime()} style={{ width: '100%', height: '100%' }}></iframe>

            </Card>
        )
    }
}

// 容器组件
const mapStateToProps = (state) => {
    return { loginData: state.login }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(DataBoard)