import React, { Component } from 'react';
import { Card, message } from 'antd';
import moment from 'moment';
import { connect } from 'react-redux';
import DateilFrom from '../../components/FormTemplate/DateilFrom';
import axios from 'axios';
import baseConfig from '../../config/BaseConfig';
import { reduceBreadcrumber } from '../../redux/actions/Breadcrumber';
import './AssessorDetail.less';
import { ADD_USER, EDIT_USER, FORM_DATEPICKER, FORM_INPUT, FORM_PASS, FORM_SELECT, FORM_TAG } from '../../config/constant';
import NationData from '../../config/Nation';
import EducationData from '../../config/Education';

/* 受测者详情 */
// UI组件
class AssessorDetail extends Component {

    // 初始id
    id = 1;
    editType = ADD_USER;
    state = {
        editType: true,
    };

    // 搜索条件组件初始数据
    formItems = [
        {
            title: '编　　号',
            inputType: FORM_INPUT,
            seacrhType: 'num',
            value: this.props.location.state.num,
            span: 6,
            rules: [],
        },
        {
            title: '姓　　名',
            inputType: FORM_INPUT,
            seacrhType: 'fname',
            value: this.props.location.state.fname,
            span: 6,
            rules: [
                {
                    required: true,
                    message: '请输入姓名',
                },
                {
                    max: 20,
                    message: '输入姓名最长不超过20个字符',
                },
            ],
        },

        {
            title: '性　　别',
            inputType: FORM_SELECT,
            seacrhType: 'gender',
            value: this.props.location.state.gender,
            span: 6,
            children: [
                { title: '男', value: '1' },
                { title: '女', value: '2' }
            ],
        },
        {
            title: '出生日期',
            inputType: FORM_DATEPICKER,
            seacrhType: 'birthday',
            value: this.props.location.state.birthday,
            span: 6,
            rules: [
                {
                    required: true,
                    message: '请选择出生日期',
                },
            ],
        },
        {
            title: '民　　族',
            inputType: FORM_SELECT,
            seacrhType: 'nation',
            value: this.props.location.state.nation,
            span: 6,
            children: NationData,
        },
        {
            title: '文化程度',
            inputType: FORM_SELECT,
            seacrhType: 'education',
            value: this.props.location.state.education,
            span: 6,
            children: EducationData,
        },
        {
            title: '利　　手',
            inputType: FORM_SELECT,
            seacrhType: 'handedness',
            value: this.props.location.state.handedness,
            span: 6,
            children: [
                { title: '右手', value: '1' },
                { title: '左手', value: '2' }
            ],
        },
        {
            title: '血　　型',
            inputType: FORM_INPUT,
            seacrhType: 'blood',
            value: this.props.location.state.blood,
            span: 6,
        },
        {
            title: '身　　高',
            inputType: FORM_INPUT,
            seacrhType: 'height',
            value: this.props.location.state.height,
            span: 6,
            placeholder: '身高（单位：cm）'
        },
        {
            title: '体　　重',
            inputType: FORM_INPUT,
            seacrhType: 'weight',
            value: this.props.location.state.weight,
            span: 6,
            placeholder: '体重（单位：kg）'
        },
        {
            title: '血　　压',
            inputType: FORM_SELECT,
            seacrhType: 'blood_pressure',
            value: this.props.location.state.blood_pressure,
            span: 6,
            children: [
                { title: '正常', value: '0' },
                { title: '偏高', value: '1' },
                { title: '偏低', value: '2' }
            ],
        },
        {
            title: '血　　糖',
            inputType: FORM_SELECT,
            seacrhType: 'blood_sugar',
            value: this.props.location.state.blood_sugar,
            span: 6,
            children: [
                { title: '正常', value: '0' },
                { title: '偏高', value: '1' },
                { title: '偏低', value: '2' }
            ],
        },
        {
            title: '视\xa0\xa0力\xa0\xa0左',
            inputType: FORM_INPUT,
            seacrhType: 'eyesight_left',
            value: this.props.location.state.eyesight_left,
            span: 6,
            placeholder: '左眼视力'
        },
        {
            title: '视\xa0\xa0力\xa0\xa0右',
            inputType: FORM_INPUT,
            seacrhType: 'eyesight_right',
            value: this.props.location.state.eyesight_right,
            span: 6,
            placeholder: '右眼视力'
        },
        {
            title: '联系电话',
            inputType: FORM_INPUT,
            seacrhType: 'phone',
            value: this.props.location.state.phone,
            span: 12,
        },
        {
            title: '住　　址',
            inputType: FORM_INPUT,
            seacrhType: 'address',
            value: this.props.location.state.address,
            span: 24,
        },
        {
            title: '描　　述',
            inputType: FORM_INPUT,
            seacrhType: 'disease_history',
            value: this.props.location.state.disease_history,
            span: 12,
            placeholder: '描述信息'
        },
        {
            title: '用\xa0\xa0药\xa0\xa0史',
            inputType: FORM_INPUT,
            seacrhType: 'medicine_history',
            value: this.props.location.state.medicine_history,
            span: 12,
        },
        {
            title: '密\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0码',
            inputType: FORM_PASS,
            seacrhType: 'pass',
            value: '',
            span: 12,
            rules: [
                {
                    required: true,
                    message: '请输入密码',
                },
            ],
        },
    ];

    // 表单初始值
    formInitialValues = {
        num: '',
        fname: '',
        gender: '',
        birthday: undefined,
        nation: '1',
        education: '1',
        handedness: '',
        blood: '',
        height: '',
        weight: '',
        blood_pressure: '1',
        blood_sugar: '1',
        eyesight_left: '',
        eyesight_right: '',
        phone: '',
        address: '',
        disease_history: '',
        medicine_history: '',
    };

    // 提交表单
    saveFormData = (data) => {
        data.id = this.id;
        data.birthday = moment(data.birthday).format('YYYY-MM-DD');
        axios.post(baseConfig.httpUrl + `/api/user/create-or-edit-tester`, data).then(
            response => {
                if (response.data.res === 1) {
                    message.success(response.data.msg);
                    if (this.id === 0) {
                        this.detailFormRef.onReset();
                    }
                    else {
                        this.props.reduce(2);
                        this.props.history.goBack();
                    }
                }
                else {
                    message.error(response.data.msg);
                }
            }
        )
    }

    // 重置数据
    remakeData = () => {

        this.id = this.props.location.state.id === undefined ? 0 : this.props.location.state.id;

        if (this.id !== 0) {
            this.editType = EDIT_USER;
        }
        else {
            this.editType = ADD_USER;
        }

        for (let i = 0; i < this.formItems.length; i++) {
            if (this.formItems[i].inputType === FORM_INPUT) {
                this.formItems[i].value = this.formItems[i].value === null ? '' : this.formItems[i].value;
            }
            else if (this.formItems[i].inputType === FORM_SELECT) {
                this.formItems[i].value = this.formItems[i].value === null ? this.formItems[i].children[0].value : this.formItems[i].value + '';
            }
            else if (this.formItems[i].inputType === FORM_DATEPICKER) {
                this.formItems[i].value = this.formItems[i].value === null ? '' : this.formItems[i].value + '';
            }

            if (this.formItems[i].seacrhType === 'birthday') {
                this.formInitialValues[this.formItems[i].seacrhType] = moment(this.formItems[i].value === 'undefined' ? undefined : this.formItems[i].value);
            }
            else {
                this.formInitialValues[this.formItems[i].seacrhType] = this.formItems[i].value + '';
            }
        }

    }

    render() {
        this.remakeData();
        const labels = this.props.location.state.labels ? this.props.location.state.labels : []
        return (
            <Card className='AssessorDetail'>
                <DateilFrom
                    onRef={c => this.detailFormRef = c}
                    readOnlyType={this.props.location.state.currnetRole === 8 ? true : false || this.props.location.state.share_status === 2 ? true : false}
                    formData={this.formItems}
                    formInitialValue={this.formInitialValues}
                    formType={this.editType}
                    labels={labels}
                    submitFun={this.saveFormData}
                />
            </Card>
        )
    }
}

// 容器组件
const mapStateToProps = (state) => {
    return { breadcrumberData: state.breadcrumber }
}

const mapDispatchToProps = (dispatch) => {
    return {
        reduce: (data) => { dispatch(reduceBreadcrumber(data)) }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AssessorDetail)