import React, { Component } from 'react'
import { connect } from 'react-redux'
import { DomainData, CompanyDataList } from '../../config/Common'
import './index.less'
import gongan from './img/gongan.png'

/* 登录 */
// UI组件
class Contact extends Component {

  render() {
    let loginName = '脑云梯脑智综合评估系统', companyName = '', mail = '', address = '';
    let domainUrl = window.location.host;
    for (let key in DomainData) {
      if (domainUrl == key) {
        loginName = DomainData[key];
      }
    }

    CompanyDataList.forEach((item) => {
      if (domainUrl == item['host']) {
        companyName = item['companyName'];
        address = item['address'];
        mail = item['mail'];
      }
    })

    return (
      <div className='html'>
        <div className="header">
          <div className="container">
            <div className="logo">{loginName}</div>
          </div>
        </div>

        <div className="content">
          <div className="container">
            <h1 className='h1Title'>联系方式</h1>
            <p>地址：北京经济技术开发区科创十二街8号北斗产业创新基地2号楼A座4层</p>
            <p>电话：4006 818 360 </p>
            <p>邮箱：market@cusoft.net</p>
            <p>邮编：100085</p>
            <p>手机：18511186997</p>
          </div>
        </div>

        <div className="footer">
          <div className="container">
            <div className="footer-links">
              <a href="/penguin/public/index.php/about">关于我们</a> |
              <a href="/penguin/public/index.php/contact">联系方式</a> |
              <a href="/penguin/public/index.php/privacy">隐私政策</a>
            </div>
            <p>Copyright 2004-2024 北京视友科技有限责任公司 版权所有</p>
            <p>
              <a href="https://beian.miit.gov.cn/" target="_blank">京ICP备05005555号-2</a>　|　
              <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010802029718" target="_blank">
                <img src={gongan} alt="京公网安备图标" />
                京公网安备 11010802029718号
              </a>
            </p>
          </div>
        </div>
      </div>
    )
  }
}

// 容器组件
const mapStateToProps = (state) => {
  return {}
}

const mapDispatchToProps = (dispatch) => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(Contact);