import React, { Component } from 'react';
import { Button, Card, Table, Space, Pagination, message, Modal, Tooltip } from 'antd';
import { DeleteOutlined, EditOutlined, LockOutlined } from '@ant-design/icons';
import axios from 'axios';
import { connect } from 'react-redux';
import baseConfig from '../../config/BaseConfig';
import SearchCriteria from '../../components/SearchCriteria/SearchCriteria';
import { addBreadcrumber } from '../../redux/actions/Breadcrumber';
import '../../components/SearchCriteria/SearchCriteria.less';
import { BATCH, SINGLE } from '../../config/constant';
import ResetPwdForm from '../../components/FormTemplate/ResetPwdForm';

class UserManage extends Component {

    // 组件状态
    state = {
        list: [],
        currentPage: 1,
        count: 1,
        batchselectedKeys: [],
        singleSelecteKey: 0,
        deleteModalVisible: false,
        deleteModalText: '确定要删除用户【张三】吗？',
        deleteType: SINGLE,
        resetPwdModalVisible: false,
        resetPwdSingleKey: 0,
        tableLoading: false,
    };

    // 搜索条件数值
    seacrhParam = {
        fname: '',
        num: '',
        uname: '',
        phone: '',
        role: '0',
    };

    // 修改密码用户信息
    resetPwdParam = {
        fname: '',
        num: '',
        uname: '',
        phone: '',
        role: '',
    };

    // 列表项
    columns = [
        {
            title: '编号',
            dataIndex: 'num',
            align: 'center',
            width: 200,
        },

        {
            title: '姓名',
            dataIndex: 'fname',
            align: 'center',
            width: 200,
        },
        {
            title: '登录名',
            dataIndex: 'uname',
            align: 'center',
            width: 200,
        },
        {
            title: '性别',
            dataIndex: 'gender',
            align: 'center',
            render: (gender) => { return Number(gender) === 1 ? '男' : Number(gender) === 2 ? '女' : '未知' },
            width: 100,
        },
        {
            title: '角色',
            dataIndex: 'role',
            align: 'center',
            width: 100,
            render: (role) => { return Number(role) === 2 ? '被测者' : Number(role) === 4 ? '操作员' : Number(role) === 8 ? '管理员' : '助理' },
        },
        {
            title: '联系电话',
            dataIndex: 'phone',
            align: 'center',
            width: 150,
        },
        {
            title: '操作',
            dataIndex: 'operator',
            align: 'center',
            width: 150,
            render: (text, record) => (
                <Space size="middle">
                    <Tooltip title="编辑用户">
                        <Button shape="circle" onClick={() => { this.goUserDetail(record) }}><EditOutlined /></Button>
                    </Tooltip>
                    <Tooltip title="重置用户密码">
                        <Button shape="circle" onClick={() => { this.resetUserPwd(record) }}><LockOutlined /></Button>
                    </Tooltip>
                    <Tooltip title="删除用户">
                        <Button shape="circle" onClick={() => { this.deleteUserData([record]) }}><DeleteOutlined /></Button>
                    </Tooltip>
                </Space>
            )
        },
    ];

    // 搜索条件组件初始数据
    seacrhItems = [
        { title: '姓名', inputType: 'input', seacrhType: 'fname' },
        { title: '编号', inputType: 'input', seacrhType: 'num' },
        { title: '登录名', inputType: 'input', seacrhType: 'uname' },
        { title: '联系电话', inputType: 'input', seacrhType: 'phone' },
        { title: '角色', inputType: 'select', seacrhType: 'role', children: [{ title: '全部', value: '0' }, { title: '操作员', value: '4' }, { title: '助理', value: '16' }] },
        // { title: '', inputType: 'buttons', seacrhType: '', children: [{ title: '搜索', key: 'submit' }, { title: '新增', key: 'add' }, { title: '删除', key: 'delete' }, { title: '导出', key: 'export' }] },
        { title: '', inputType: 'buttons', seacrhType: '', children: [{ title: '新增', key: 'add' }, { title: '删除', key: 'delete' }, { title: '导出', key: 'export' }] },
    ];

    // 操作员名称
    operatorName = '操作员';

    // 助理名称
    assistantName = '助理';

    // 组件挂载
    componentDidMount() {
        this.getRoleName(4);
        this.getRoleName(16);
        this.seacrhDataFun({ page: 1 });
    }

    // 获取角色名字
    getRoleName = (roleNum) => {
        axios.get(baseConfig.httpUrl + `/api/group/get-role-name`, { params: { 'role': roleNum } }).then(
            response => {
                if (response.data.res === 1) {
                    if (roleNum === 4) {
                        this.operatorName = response.data.role_name;
                    }
                    else if (roleNum === 16) {
                        this.assistantName = response.data.role_name;
                    }
                }
                else {
                    message.error(response.data.msg);
                }
            }
        )
    }

    // 跟新搜索条件数值
    updateSeacrhItems = (seacrhData) => {
        let tempObj = this.formatParams(seacrhData);
        Object.assign(this.seacrhParam, tempObj);
    }

    // 每条数据详情
    goUserDetail = (data) => {
        this.props.add({ path: `/userdetail`, name: '用户详情' });
        this.props.history.push(baseConfig.routeUrl + `/userdetail`,
            {
                role: data.role,
                num: data.num,
                fname: data.fname,
                uname: data.uname,
                phone: data.phone,
                gender: data.gender,
                id: data.id,
                operatorname: this.operatorName,
                assistantname: this.assistantName,
            });
    }

    // 导出
    exportDataFun = () => {
        let exportUrl = baseConfig.httpUrl + '/api/user/get-operator-list?';
        for (let key in this.seacrhParam) {
            if (this.seacrhParam[key] !== undefined) {
                exportUrl += key + '=' + this.seacrhParam[key] + '&';
            }
        }
        exportUrl += 'export=1';
        window.open(exportUrl, '_blank');
    }

    // 新增用户
    addUser = () => {
        this.props.add({ path: `/userdetail`, name: '用户详情' });
        this.props.history.push(baseConfig.routeUrl + `/userdetail`,
            {
                role: '4',
                num: '',
                fname: '',
                uname: '',
                phone: '',
                gender: '1',
                id: 0,
                operatorname: this.operatorName,
                assistantname: this.assistantName,
            });
    }

    // 重置用户密码
    resetUserPwd = (data) => {
        let tempObj = this.formatParams(data);
        Object.assign(this.resetPwdParam, tempObj);
        this.setState({ resetPwdModalVisible: true });
    }

    // 提供用户信息
    supplyUserInfo = () => {
        return this.resetPwdParam;
    }

    // 删除用户
    deleteUserData = (data) => {
        const { batchselectedKeys } = this.state;

        let tempdeleteModalText = '';
        if (data) {
            tempdeleteModalText = `确定要删除用户 【${data[0].fname}】 吗？`;
            this.setState({ deleteModalVisible: true, deleteModalText: tempdeleteModalText, deleteType: SINGLE, singleSelecteKey: data[0].id });
        }
        else {
            if (batchselectedKeys.length < 1) {
                message.warning({
                    content: '请选择要删除的用户',
                    duration: 3
                });
            }
            else {
                tempdeleteModalText = `确定要删除用户选中吗？`;
                this.setState({ deleteModalVisible: true, deleteModalText: tempdeleteModalText, deleteType: BATCH });
            }
        }
    }

    // 点击确定-删除
    deleteOk = () => {
        let tempParam = this.state.deleteType === SINGLE ? [this.state.singleSelecteKey] : this.state.batchselectedKeys;
        axios.post(baseConfig.httpUrl + `/api/user/delete-user`, { ids: tempParam }).then(
            response => {
                this.setState({ deleteModalVisible: false })
                if (response.data.res === 1) {

                    message.success({
                        content: '成功',
                        className: 'custom-class',
                    });

                    // 重新获取列表数据
                    this.seacrhDataFun();
                }
                else {
                    message.error({
                        content: response.data.msg,
                        className: 'custom-class',
                    });
                }
            }
        )
    }

    // 关闭弹框-删除
    deleteCancel = () => {
        this.setState({ deleteModalVisible: false });
    }

    // 关闭弹框-修改密码
    resetCancel = () => {
        this.setState({ resetPwdModalVisible: false });
    }

    // 列表 每行数据选择checkbox
    onSelectChange = batchselectedKeys => {
        this.setState({ batchselectedKeys });
    };

    // 格式化参数
    formatParams = (seacrhParam) => {
        for (let key in seacrhParam) {
            seacrhParam[key] = seacrhParam[key] === undefined ? '' : seacrhParam[key];
        }
        return seacrhParam;
    }

    // 获取列表数据
    seacrhDataFun = (data = null) => {
        let tempParam = {}
        Object.assign(tempParam, this.seacrhParam);
        tempParam.page = this.state.currentPage

        if (data) {
            tempParam.page = data.page
        }

        this.setState({ tableLoading: true });
        axios.get(baseConfig.httpUrl + `/api/user/get-operator-list`, { params: tempParam }).then(
            response => {
                if (response.data.res === 1) {
                    const { data, page, count } = response.data;

                    data.map((dataObj) => {
                        return dataObj.key = dataObj.id;
                    });

                    this.setState(
                        {
                            list: [...data],
                            currentPage: page * 1,
                            count: count,
                            batchselectedKeys: [],
                            tableLoading: false
                        }
                    );
                }
                else {
                    this.setState({
                        list: [],
                        currentPage: 1,
                        count: 1,
                        batchselectedKeys: [],
                        tableLoading: false
                    });
                    message.error({
                        content: response.data.msg,
                        className: 'custom-class',
                    });
                }
            },
            error => { }
        )
    }

    // 换页
    changePage = (pageNum, pageSize) => {
        this.seacrhDataFun({ page: pageNum });
    }

    render() {
        const { batchselectedKeys } = this.state;
        const rowSelection = {
            batchselectedKeys,
            onChange: this.onSelectChange,
        };

        return (
            <Card title="" style={{ minHeight: '100%' }}>

                {/* 删除用户 */}
                <Modal
                    className='modals'
                    title="删除用户"
                    visible={this.state.deleteModalVisible}
                    onOk={this.deleteOk}
                    onCancel={this.deleteCancel}
                    maskClosable={false}
                >
                    <p className='modalText'>{this.state.deleteModalText}</p>
                </Modal>

                {/* 修改密码 */}
                <Modal
                    className='modals modals-noButton'
                    title="重置用户密码"
                    visible={this.state.resetPwdModalVisible}
                    onCancel={this.resetCancel}
                    maskClosable={false}
                >
                    <ResetPwdForm userInfo={this.supplyUserInfo} closeModal={this.resetCancel} />
                </Modal>

                {/* 搜索条件 */}
                <div>
                    <SearchCriteria
                        seacrhItems={this.seacrhItems}
                        addFun={this.addUser}
                        deleteFun={this.deleteUserData}
                        exportFun={this.exportDataFun}
                        seacrhFun={this.seacrhDataFun}
                        updateSeacrhValue={this.updateSeacrhItems}
                        initialValueParam={{ role: '0' }}
                    />
                </div>

                {/* 列表 */}
                <Table
                    dataSource={this.state.list}
                    pagination={false}
                    columns={this.columns}
                    rowSelection={rowSelection}
                    loading={this.state.tableLoading}
                >
                </Table>

                {/* 分页 */}
                <Pagination
                    onChange={this.changePage}
                    style={{ marginTop: 30 }}
                    defaultCurrent={1}
                    current={this.state.currentPage}
                    total={this.state.count}
                    hideOnSinglePage={true}
                    showQuickJumper={{
                        goButton: <span><span>&nbsp;&nbsp;&nbsp;&nbsp;</span> <Button type='primary'>Go</Button></span>,
                    }}
                    showTotal={(total, range) => `当前显示${range[0]}-${range[1]}条  共${total}条记录 `}
                    showSizeChanger={false}
                />
            </Card >
        )
    }
}

// 容器组件
const mapStateToProps = (state) => {
    return { breadcrumberData: state.breadcrumber }
}

const mapDispatchToProps = (dispatch) => {
    return {
        add: (data) => { dispatch(addBreadcrumber(data)) }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserManage)