import React, { Component } from 'react';
import { message, Card, Row, Col, Input, Button, Space, Modal } from 'antd';
import axios from 'axios';
import baseConfig from '../../config/BaseConfig';
import './AccountRecharge.less';

/* 账户充值 */
export default class AccountRecharge extends Component {

    customAcountRef = React.createRef();

    state = {
        accountBalance: 0,
        errorText: '',
        orderModalVisible: false,
    };

    moneyBtns = ['btn5000', 'btn1000', 'btn500', 'btn200', 'btn100', 'btn50', 'btn10', 'btn0'];
    payBtns = ['pay13', 'pay12', 'pay5'];
    money = 0;
    payway = 0;
    orderId = 0;

    componentDidMount() {
        this.balanceDetail();
    }

    // 余额
    balanceDetail = () => {
        axios.get(baseConfig.httpUrl + '/api/pay/balance').then(
            response => {
                if (response.data.res === 1) {
                    this.setState({ accountBalance: response.data.balance });
                }
                else {
                    message.error(response.data.msg);
                }
            }
        );
    }

    // 选择金额
    chooseAmount = (data) => {
        return () => {
            let tempId = data === null ? 0 : data;

            // 选中样式
            for (let i = 0; i < this.moneyBtns.length; i++) {
                if (this.moneyBtns[i] === 'btn' + tempId) {
                    let tempClass = document.getElementById(this.moneyBtns[i]).getAttribute('class');
                    document.getElementById(this.moneyBtns[i]).setAttribute('class', tempClass + ' btn_active');
                }
                else {
                    document.getElementById(this.moneyBtns[i]).setAttribute('class', i === this.moneyBtns.length - 1 ? 'ant-input' : 'ant-btn');
                }
            }

            if (data !== null) {
                this.money = data;
            }
        }
    }

    // 自定义金额
    customAcount = () => {
        let acountNum = this.customAcountRef.current.state.value;
        if (acountNum) {
            this.money = Number(acountNum);
        }
        else {
            this.money = 0;
        }
    }

    // 选择支付方式
    choosePayway = (data) => {
        return () => {
            // 选中样式
            for (let i = 0; i < this.payBtns.length; i++) {
                if (this.payBtns[i] === 'pay' + data) {
                    let tempClass = document.getElementById(this.payBtns[i]).getAttribute('class');
                    document.getElementById(this.payBtns[i]).setAttribute('class', tempClass + ' btn_active');
                }
                else {
                    document.getElementById(this.payBtns[i]).setAttribute('class', 'ant-btn');
                }
            }

            this.payway = data;
        }
    }

    // 生成订单
    createOrder = () => {
        if (this.money === 0) {
            this.setState({ errorText: '请选择或输入正确的充值金额' });
        }
        else if (this.payway === 0) {
            this.setState({ errorText: '请选择支付方式' });
        }
        else {
            this.setState({ errorText: '' });
            axios.post(baseConfig.httpUrl + '/api/pay/confirm-order', { money: this.money, pay_type: this.payway }).then(
                response => {
                    if (response.data.res === 1) {
                        this.orderId = response.data.order_id;
                        this.setState({ orderModalVisible: true });
                    }
                    else {
                        message.error(response.data.msg);
                    }
                }
            );
        }
    }

    // 关闭弹框
    modalCancel = () => {
        this.setState({ orderModalVisible: false });
    }

    // 确定支付
    modalOk = () => {
        if (this.payway === 5) {
            this.setState({ orderModalVisible: false });
            this.balanceDetail();
        }
        else if (this.payway === 12) {
            this.setState({ orderModalVisible: false });
            window.open(baseConfig.httpUrl + '/api/pay/recharge-alipay?order_id=' + this.orderId, '_blank');
        }
    }

    render() {
        return (
            <Card title="账户充值" className='AccountRecharge' style={{ minHeight: '100%' }}>

                {/* 修改密码 */}
                <Modal
                    className='modals AccountRecharge-modals'
                    title="订单确认"
                    visible={this.state.orderModalVisible}
                    onCancel={this.modalCancel}
                    onOk={this.modalOk}
                    maskClosable={false}
                >
                    <Row style={{ marginTop: '10px' }}>
                        <Col span={4} className='textAlignR'><span className='lineHeight32'>充值金额：</span></Col>
                        <Col span={20} ><span className='lineHeight32'>￥{this.money}</span></Col>
                    </Row>
                    <Row style={{ marginTop: '10px' }}>
                        <Col span={4} className='textAlignR'><span className='lineHeight32'>订&nbsp;&nbsp;单&nbsp;&nbsp;号：</span></Col>
                        <Col span={20} ><span className='lineHeight32'>{this.orderId}</span></Col>
                    </Row>
                    <Row style={{ marginTop: '10px' }}>
                        <Col span={4} className='textAlignR'><span className='lineHeight32'>支付方式：</span></Col>
                        <Col span={20} ><span className='lineHeight32'>{this.payway === 13 ? '微信' : this.payway === 12 ? '支付宝' : '线下支付'}</span></Col>
                    </Row>
                    <Row style={{ marginTop: '10px' }}>
                        <Col span={4} className='textAlignR'><span className='lineHeight32'>订单说明：</span></Col>
                        <Col span={20} ><span className='lineHeight32'>充值金额不支持退款和兑现，只能用于平台消费</span></Col>
                    </Row>
                </Modal>

                <Row>
                    <Col span={2} className='textAlignR'><span className='lineHeight32'>当前余额：</span></Col>
                    <Col span={12} style={{ paddingLeft: '25px' }}><span className='lineHeight32'>￥{this.state.accountBalance}</span></Col>
                </Row>
                <Row style={{ marginTop: '25px' }}>
                    <Col span={2} className='textAlignR'><span className='lineHeight32'>充值金额：</span></Col>
                    <Col span={22}>
                        <Space size={25} style={{ paddingLeft: '25px' }}>
                            <Button id='btn5000' onClick={this.chooseAmount(5000)} style={{ width: '80px' }}>5000元</Button>
                            <Button id='btn1000' onClick={this.chooseAmount(1000)} style={{ width: '80px' }}>1000元</Button>
                            <Button id='btn500' onClick={this.chooseAmount(500)} style={{ width: '80px' }}>500元</Button>
                            <Button id='btn200' onClick={this.chooseAmount(200)} style={{ width: '80px' }}>200元</Button>
                            <Button id='btn100' onClick={this.chooseAmount(100)} style={{ width: '80px' }}>100元</Button>
                            <Button id='btn50' onClick={this.chooseAmount(50)} style={{ width: '80px' }}>50元</Button>
                            <Button id='btn10' onClick={this.chooseAmount(10)} style={{ width: '80px' }}>10元</Button>
                            <Input id='btn0' onClick={this.chooseAmount(null)} onBlur={this.customAcount} type='number' style={{ width: '80px' }} ref={this.customAcountRef} />
                        </Space>
                        <span style={{ lineHeight: '30px' }}> 元</span>
                    </Col>
                </Row>
                <Row style={{ marginTop: '25px' }}>
                    <Col span={2} className='textAlignR'><span className='lineHeight32'>支付方式：</span></Col>
                    <Col span={22}>
                        <Space size={25} style={{ paddingLeft: '25px' }}>
                            <Button id='pay12' onClick={this.choosePayway(12)} style={{ width: '100px' }}>支付宝</Button>
                            <Button id='pay5' onClick={this.choosePayway(5)} style={{ width: '100px' }}>线下充值</Button>
                            <Button id='pay13' onClick={this.choosePayway(13)} style={{ width: '100px', display: 'none' }}>微信</Button>
                        </Space>
                    </Col>
                </Row>
                <Row style={{ marginTop: '25px' }}>
                    <Col span={2} className='textAlignR'><span className='lineHeight32'>支付方式：</span></Col>
                    <Col span={2} style={{ paddingLeft: '25px' }}>
                        <Button onClick={this.createOrder} type='primary' style={{ width: '100px' }}>充值</Button>
                    </Col>
                    <Col span={6}>
                        <span style={{ lineHeight: '32px', color: '#ff0000' }}>{this.state.errorText}</span>
                    </Col>
                </Row>
            </Card>
        )
    }
}
