/* 
    该文件专门用于暴露一个store对象，整个应用只有一个store对象
*/

//引入createStore，专门用于创建redux中最为核心的store对象
import { createStore, applyMiddleware, combineReducers } from 'redux'
import loginReducer from './reducers/Login'
import adminReducer from './reducers/Admin'
import leftNavReducer from './reducers/LeftNav'
import breadcrumberReducer from './reducers/Breadcrumber'
import userManageReducer from './reducers/UserManage'

//引入redux-thunk，用于支持异步action
import thunk from 'redux-thunk'
// redux 开发者工具
import { composeWithDevTools } from 'redux-devtools-extension'
import { persistStore, persistReducer } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';

const persistConfig = {
    key: 'root',
    storage: storageSession, // 缓存机制  
    blacklist: [] //reducer 里不持久化的数据,除此外均为持久化数据  
    // whitelist: ['loginStatus'] // reducer 里持久化的数据,除此外均为不持久化数据
}

//汇总所有的reducer变为一个总的reducer
const allReducer = combineReducers({
    login: loginReducer,
    admin: adminReducer,
    leftNav: leftNavReducer,
    breadcrumber: breadcrumberReducer,
    userManage: userManageReducer,
})

const persistedReducer = persistReducer(persistConfig, allReducer);
const store = createStore(persistedReducer, composeWithDevTools(applyMiddleware(thunk)));

export const persistor = persistStore(store);
export default store;
//暴露store
// export default createStore(allReducer, composeWithDevTools(applyMiddleware(thunk)))