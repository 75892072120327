import React, { Component } from 'react';
import { Menu, Button, message } from 'antd';
import { StepBackwardOutlined, StepForwardOutlined, CaretLeftOutlined, CaretRightOutlined } from '@ant-design/icons';
import './ShareEdit.less';
import { BATCH, SINGLE } from '../../config/constant';
import axios from 'axios';
import baseConfig from '../../config/BaseConfig';

export default class ShareEdit extends Component {

    constructor(props) {
        super(props);

        // 已共享的人
        let hasShareArr = [];
        // 可共享的人
        let shareArr = [];

        if (props.shareManageType === SINGLE) {
            props.currentUser.share_user.map((tempObj) => {
                hasShareArr.push({ id: tempObj.id, fname: tempObj.fname });
                return true;
            });

            for (let i = 0; i < props.allOperatorData.length; i++) {
                let tempType = true;
                for (let j = 0; j < hasShareArr.length; j++) {
                    if (props.allOperatorData[i].id === hasShareArr[j].id) {
                        tempType = false;
                    }
                }

                if (tempType) {
                    shareArr.push(props.allOperatorData[i]);
                }
            }
        }
        else {
            shareArr = [...props.allOperatorData];
        }

        this.state = {
            leftOperators: shareArr,
            rightOperators: hasShareArr,
            currentShare: {},
            currentUserIds: props.currentUserIds,
            shareManageType: props.shareManageType,
            rightOperatorDisplay: '',
            leftOperatorMargin: '',
        };

        if (props.shareManageType === SINGLE) {
            this.state.rightOperatorDisplay = 'block';
            this.state.leftOperatorMargin = 0;
        }
        else if (props.shareManageType === BATCH) {
            this.state.rightOperatorDisplay = 'none';
            this.state.leftOperatorMargin = 200;
        }
    }

    // 点击的人
    currentShare = {
        id: '',
        fname: '',
        from: ''
    };

    // 创建目录
    menuOperators = (menuList) => {
        return menuList.map((menuObj) => {
            return (<Menu.Item key={menuObj.id}>{menuObj.fname}</Menu.Item>);
        })
    }

    // 点击左边目录
    clickMenuLeft = (e) => {
        this.currentShare.id = e.key;
        this.currentShare.fname = e.domEvent.target.innerText;
        this.currentShare.from = 'left';
    }

    // 点击右边目录
    clickMenuRight = (e) => {
        this.currentShare.id = e.key;
        this.currentShare.fname = e.domEvent.target.innerText;
        this.currentShare.from = 'right';
    }

    // 移动到右边-所有
    moveRightAll = () => {
        this.setState({
            leftOperators: [],
            rightOperators: [...this.state.rightOperators, ...this.state.leftOperators],
        });
    }

    // 移动到左边
    moveLeft = () => {
        if (!this.currentShare.id) {
            return false;
        }
        else if (this.currentShare.from === 'left') {
            return false;
        }

        let tempArr = [...this.state.rightOperators];
        for (let i = 0; i < tempArr.length; i++) {
            if (Number(tempArr[i].id) === Number(this.currentShare.id)) {
                tempArr.splice(i, 1);
            }
        }

        this.setState({
            leftOperators: [...this.state.leftOperators, { id: this.currentShare.id, fname: this.currentShare.fname }],
            rightOperators: [...tempArr],
        })

        this.currentShare.id = '';
        this.currentShare.fname = '';
        this.currentShare.from = '';
    }

    // 移动到右边
    moveRight = () => {
        if (!this.currentShare.id) {
            return false;
        }
        else if (this.currentShare.from === 'right') {
            return false;
        }

        let tempArr = [...this.state.leftOperators];
        for (let i = 0; i < tempArr.length; i++) {
            if (Number(tempArr[i].id) === Number(this.currentShare.id)) {
                tempArr.splice(i, 1);
            }
        }

        this.setState({
            leftOperators: [...tempArr],
            rightOperators: [...this.state.rightOperators, { id: this.currentShare.id, fname: this.currentShare.fname }],
        })

        this.currentShare.id = '';
        this.currentShare.fname = '';
        this.currentShare.from = '';
    }

    // 移动到左边-所有
    moveLeftAll = () => {
        this.setState({
            leftOperators: [...this.state.leftOperators, ...this.state.rightOperators],
            rightOperators: [],
        });
    }

    // 提交共享管理
    submitShare = () => {
        let toWhoParam = [];
        let clearParam = 0;
        if (this.state.shareManageType === SINGLE) {
            let rightOperatorIds = this.state.rightOperators.map((tempObj) => { return tempObj.id });
            toWhoParam = [...rightOperatorIds];
            clearParam = 1;
        }
        else if (this.state.shareManageType === BATCH) {
            toWhoParam = [this.currentShare.id];
            clearParam = 0;
        }

        axios.post(baseConfig.httpUrl + '/api/user/create-share', { to_who: toWhoParam, tester: this.state.currentUserIds, clear_before_create: clearParam }).then(
            response => {
                if (response.data.res === 1) {
                    message.success(response.data.msg);
                    this.props.seacrhFun();
                    this.props.closeModal();
                }
                else {
                    message.error(response.data.msg);
                }
            }
        );
    }

    render() {
        return (
            <div className='shareEdit'>
                <div className='share-left' style={{ marginLeft: this.state.leftOperatorMargin }}>
                    <p className='share-text'>可选共享人</p>
                    <div className='share-content'>
                        <Menu
                            onClick={this.clickMenuLeft}
                            style={{ width: 231 }}
                            mode="inline"
                        >
                            {
                                this.menuOperators(this.state.leftOperators)
                            }
                        </Menu>
                    </div>
                </div>
                <div className='share-operator' style={{ display: this.state.rightOperatorDisplay }}>
                    <Button className='share-operator-btn' icon={<StepForwardOutlined />} onClick={this.moveRightAll} />
                    <Button className='share-operator-btn' icon={<CaretRightOutlined />} onClick={this.moveRight} />
                    <Button className='share-operator-btn' icon={<CaretLeftOutlined />} onClick={this.moveLeft} />
                    <Button className='share-operator-btn' icon={<StepBackwardOutlined />} onClick={this.moveLeftAll} />
                </div>
                <div className='share-right' style={{ display: this.state.rightOperatorDisplay }}>
                    <p className='share-text'>已共享人</p>
                    <div className='share-content'>
                        <Menu
                            onClick={this.clickMenuRight}
                            style={{ width: 231 }}
                            mode="inline"
                        >
                            {
                                this.menuOperators(this.state.rightOperators)
                            }
                        </Menu>
                    </div>
                </div>
                <div className='share_btn'>
                    <Button type="primary" shape="round" size='large' style={{ width: 100 }} onClick={this.submitShare}>确定</Button>
                </div>
            </div>
        )
    }
}
