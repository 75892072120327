import React, { Component } from 'react';
import { Card, message } from 'antd';
import { connect } from 'react-redux';
import DateilFrom from '../../components/FormTemplate/DateilFrom';
import axios from 'axios';
import baseConfig from '../../config/BaseConfig';
import { reduceBreadcrumber } from '../../redux/actions/Breadcrumber';

import './UserDetail.less';
import { ADD_USER, EDIT_USER, FORM_SELECT, FORM_INPUT } from '../../config/constant';

/* 用户详情 */
// UI组件
class UserDetail extends Component {

    // 初始id
    id = 1;
    editType = ADD_USER;
    state = {
        editType: true,
    };

    // 搜索条件组件初始数据
    formItems = [
        {
            title: '姓\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0名',
            inputType: FORM_INPUT,
            seacrhType: 'fname',
            value: this.props.location.state.fname,
            span: 12,
            rules: [
                {
                    required: true,
                    message: '请输入姓名',
                },
                {
                    max: 20,
                    message: '输入姓名最长不超过20个字符',
                },
            ]
        },
        {
            title: '编\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0号',
            inputType: FORM_INPUT,
            seacrhType: 'num',
            value: this.props.location.state.num,
            span: 12,
            rules: []
        },
        {
            title: '登\xa0\xa0录\xa0\xa0名',
            inputType: FORM_INPUT,
            seacrhType: 'uname',
            value: this.props.location.state.uname,
            span: 12,
            rules: [
                {
                    required: true,
                    message: '请输入登录名',
                },
            ]
        },
        {
            title: '联系电话',
            inputType: FORM_INPUT,
            seacrhType: 'phone',
            value: this.props.location.state.phone,
            span: 12,
            rules: [
                {
                    required: true,
                    message: '请输入联系电话',
                },
                {
                    pattern: new RegExp(/^1\d{10}$/, "g"),
                    message: '输入联系电话格式不正确',
                },
            ]
        },
        {
            title: '性\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0别',
            inputType: FORM_SELECT,
            seacrhType: 'gender',
            value: this.props.location.state.gender,
            span: 12,
            children: [
                { title: '男', value: '1' },
                { title: '女', value: '2' }
            ]
        },
        {
            title: '角\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0色',
            span: 12,
            inputType: FORM_SELECT,
            seacrhType: 'role',
            value: this.props.location.state.role,
            disabled: true,
            children: [
                { title: this.props.location.state.operatorname, value: '4' },
                { title: this.props.location.state.assistantname, value: '16' }
            ]
        },
        {
            title: '密\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0码',
            inputType: FORM_INPUT,
            seacrhType: 'pass',
            value: this.props.location.state.pass,
            span: 12,
            rules: [
                {
                    required: true,
                    message: '请输入密码',
                },
                {
                    max: 16,
                    message: '输入密码最长不超过16个字符',
                },
            ]
        }
    ];

    // 表单初始值
    formInitialValues = {
        role: '4',
        fname: '',
        num: '',
        uname: '',
        phone: '',
        gender: '1',
    };

    // 提交表单
    saveFormData = (data) => {
        data.id = this.id;
        axios.post(baseConfig.httpUrl + `/api/user/create-or-edit-operator`, data).then(
            response => {
                if (response.data.res === 1) {
                    message.success(response.data.msg);
                    if (this.id === 0) {
                        this.detailFormRef.onReset()
                    }
                    else {
                        this.props.reduce(2);
                        this.props.history.goBack();
                    }
                }
                else {
                    message.error(response.data.msg);
                }
            }
        )
    }

    // 重置数据
    remakeData = () => {
        this.id = this.props.location.state.id === undefined ? 0 : this.props.location.state.id;

        if (this.id !== 0) {
            this.formItems = this.formItems.filter((tempObj) => {
                return tempObj.seacrhType !== 'pass';
            })
            this.editType = EDIT_USER;
        }
        else {
            this.editType = ADD_USER;
        }

        for (let i = 0; i < this.formItems.length; i++) {
            if (this.formItems[i].inputType === FORM_INPUT) {
                this.formItems[i].value = this.formItems[i].value === undefined ? '' : this.formItems[i].value;
            }
            else if (this.formItems[i].inputType === FORM_SELECT) {
                this.formItems[i].value = this.formItems[i].value === undefined ? this.formItems[i].children[0].value : this.formItems[i].value + '';
            }

            this.formInitialValues[this.formItems[i].seacrhType] = this.formItems[i].value + '';
        }
    }

    render() {
        this.remakeData();
        return (
            <Card className='userDetail'>
                <DateilFrom
                    onRef={c => this.detailFormRef = c}
                    formData={this.formItems}
                    formInitialValue={this.formInitialValues}
                    formType={this.editType}
                    submitFun={this.saveFormData}
                />
            </Card>
        )
    }
}

// 容器组件
const mapStateToProps = (state) => {
    return { breadcrumberData: state.breadcrumber }
}

const mapDispatchToProps = (dispatch) => {
    return {
        reduce: (data) => { dispatch(reduceBreadcrumber(data)) }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserDetail)