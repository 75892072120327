import React, { Component } from 'react';
import { Button, Card, Table, Space, Pagination, message, Modal, Tooltip } from 'antd';
import { SnippetsOutlined, DeleteOutlined, } from '@ant-design/icons';
import { BATCH, SINGLE } from '../../config/constant';
import SearchCriteria from '../../components/SearchCriteria/SearchCriteria';
import axios from 'axios';
import baseConfig from '../../config/BaseConfig';
import { connect } from 'react-redux';
import { addBreadcrumber } from '../../redux/actions/Breadcrumber';
import moment from 'moment';

class ReportManage extends Component {

    constructor(props) {
        super(props);
        // 组件状态

        let tempName = props.location.state && props.location.state.name ? props.location.state.name : '';
        this.state = {
            list: [],
            currentPage: 1,
            count: 1,
            batchselectedKeys: [],
            singleSelecteKey: 0,
            deleteModalVisible: false,
            deleteModalText: '确定要删除用户【张三】吗？',
            deleteType: SINGLE,
            currnetRole: props.leftNavData.role,
            tableLoading: false,
            fname: tempName,
            searchItems: [],
            sorterType: '',
            downloadVisible: false
        };

        this.seacrhParam.fname = tempName;
    }

    // 搜索条件数值
    seacrhParam = {
        fname: '',
        num: '',
        course_id: '0',
        gender: '0',
        date_range: [],
        start_time: '',
        end_time: '',
        indicator: '0'
    };

    // 列表项
    columns = [
        // {
        //     title: '编号',
        //     dataIndex: 'num',
        //     align: 'center',
        //     width: 180,
        // },

        {
            title: '姓名',
            dataIndex: 'fname',
            align: 'center',
            width: 180,
        },
        {
            title: '测评项目',
            dataIndex: 'course_name',
            align: 'center',
            width: 200,
        },
        {
            title: '测评时间',
            dataIndex: 'create_time',
            align: 'center',
            width: 200,
        },
        {
            title: '年龄',
            dataIndex: 'age',
            align: 'center',
            width: 130,
        },
        {
            title: '性别',
            dataIndex: 'gender',
            align: 'center',
            width: 130,
            render: (gender) => { return Number(gender) === 1 ? '男' : Number(gender) === 2 ? '女' : '未知' },
        },
        {
            title: '得分',
            dataIndex: 'score',
            align: 'center',
            width: 180,
            sorter: true,
        },
        {
            title: '测评人',
            dataIndex: 'teacher_name',
            align: 'center',
            width: 180,
        },
        {
            title: '操作',
            dataIndex: 'operator',
            align: 'center',
            width: 150,
            render: (text, record) => (
                <Space size="middle">
                    <Tooltip title="查看报告">
                        <Button shape="circle" onClick={() => { this.viewReport(record) }}><SnippetsOutlined /></Button>
                    </Tooltip>
                    <Tooltip title="删除测评记录">
                        <Button shape="circle" onClick={() => { this.deleteUserData([record]) }}><DeleteOutlined /></Button>
                    </Tooltip>
                </Space>
            )
        },
    ];

    // 搜索条件组件初始数据
    seacrhItemsData = [
        { title: '姓名', inputType: 'input', seacrhType: 'fname' },
        { title: '编号', inputType: 'input', seacrhType: 'num' },
        { title: '项目', inputType: 'select', seacrhType: 'course_id', children: [{ title: '全部', value: '0' }] },
        { title: '性别', inputType: 'select', seacrhType: 'gender', children: [{ title: '全部', value: '0' }, { title: '男', value: '1' }, { title: '女', value: '2' }] },
        { title: '测评时间', inputType: 'date_range', seacrhType: 'date_range', span: 6 },
        { title: '指标', inputType: 'select', seacrhType: 'indicator', children: [{ title: '全部', value: '0' }, { title: '抑郁', value: '1' }, { title: '焦虑', value: '2' }, { title: '睡眠', value: '3' }] },
        { title: '', inputType: 'buttons', seacrhType: '', children: [{ title: '删除', key: 'delete' }, { title: '导出', key: 'export' }, { title: '下载报告', key: 'downloadfile' }] },
    ];

    // 组件挂载
    componentDidMount() {
        this.seacrhDataFun({ page: 1 });
        this.getCourseList();
    }

    /**
     * 获取课程列表
     */
    getCourseList() {

        axios.get(baseConfig.httpUrl + `/api/training/course-list`, { params: { user_id: 0 } }).then(
            response => {
                if (response.data.res === 1) {

                    let tmepArr = [...response.data.list];
                    tmepArr.forEach(item => {
                        this.seacrhItemsData[2].children.push({ title: item.name, value: item.course_id.toString() })
                    })

                    this.setState({ searchItems: this.seacrhItemsData });
                }
                else {
                    message.error(response.data.msg);
                }
            }
        );
    }

    // 获取列表数据
    seacrhDataFun = (data = null) => {

        let tempParam = {};
        Object.assign(tempParam, this.seacrhParam);
        tempParam.page = this.state.currentPage;

        if (data) {
            if (data.page) {
                tempParam.page = data.page;
            }

            if (data.order_by_score) {
                tempParam.order_by_score = data.order_by_score;
            }
            else {
                tempParam.order_by_score = this.state.sorterType
            }
        }

        if (tempParam.date_range) {
            delete tempParam.date_range
        }

        this.setState({ tableLoading: true });
        axios.get(baseConfig.httpUrl + `/api/training/evaluate-record`, { params: tempParam }).then(
            response => {
                if (response.data.res === 1) {
                    const { data, page, count } = response.data;

                    data.map((dataObj) => {
                        return dataObj.key = dataObj.id
                    });

                    this.setState({
                        list: [...data],
                        currentPage: page * 1,
                        count: count,
                        batchselectedKeys: [],
                        tableLoading: false
                    });
                }
                else {
                    this.setState({
                        list: [],
                        currentPage: 1,
                        count: 1,
                        batchselectedKeys: [],
                        tableLoading: false
                    });
                    message.error({
                        content: response.data.msg,
                        className: 'custom-class',
                    });
                }
            },
            error => { }
        );
    }

    // 跟新搜索条件数值
    updateSeacrhItems = (seacrhData) => {
        console.log('updateSeacrhItems', seacrhData)
        let tempObj = this.formatParams(seacrhData);

        if (tempObj.date_range && tempObj.date_range.length === 2) {
            tempObj.start_time = moment(tempObj.date_range[0]).format('YYYY-MM-DD');
            tempObj.end_time = moment(tempObj.date_range[1]).format('YYYY-MM-DD');
        }
        else {
            tempObj.start_time = '';
            tempObj.end_time = '';
        }

        // console.log('updateSeacrhItems', tempObj)
        Object.assign(this.seacrhParam, tempObj);
    }

    tableOnChange = (page, filter, sorter) => {
        let tempSort = '';
        switch (sorter.order) {
            case 'ascend':
                tempSort = 'asc';
                break;
            case 'descend':
                tempSort = 'desc';
                break;
            default:
                tempSort = '';
                break;
        }

        let self = this
        setTimeout(() => {
            self.setState({ sorterType: tempSort })
            self.seacrhDataFun({ order_by_score: tempSort })
        }, 0)
    }

    // 导出
    exportDataFun = () => {
        let exportUrl = baseConfig.httpUrl + '/api/training/evaluate-record?';

        let tempSeacrhParam = {};
        Object.assign(tempSeacrhParam, this.seacrhParam);
        delete tempSeacrhParam.date_range;

        for (let key in tempSeacrhParam) {
            if (tempSeacrhParam[key] !== undefined) {
                exportUrl += key + '=' + tempSeacrhParam[key] + '&'
            }
        }
        exportUrl += 'export=1'
        window.open(exportUrl, '_blank')
    }

    // 删除
    deleteUserData = (data) => {
        const { batchselectedKeys } = this.state;
        // console.log('deleteUserData-data', data)
        // console.log('deleteUserData-batchselectedKeys', batchselectedKeys)

        let tempdeleteModalText = '';
        if (data) {
            tempdeleteModalText = `您确定要删除所选的测评记录吗？
            被删除的记录数据将不可恢复！`;
            this.setState({ deleteModalVisible: true, deleteModalText: tempdeleteModalText, deleteType: SINGLE, singleSelecteKey: data[0].id });
        }
        else {
            if (batchselectedKeys.length < 1) {
                message.warning({
                    content: '请选择要删除的测评记录',
                    duration: 3
                });
            }
            else {
                tempdeleteModalText = `您确定要删除所选的测评记录吗？
                被删除的记录数据将不可恢复！`;
                this.setState({ deleteModalVisible: true, deleteModalText: tempdeleteModalText, deleteType: BATCH });
            }
        }
    }

    // 点击确定-取消删除
    deleteOk = () => {
        let tempParam = this.state.deleteType === SINGLE ? [this.state.singleSelecteKey] : this.state.batchselectedKeys;
        axios.post(baseConfig.httpUrl + `/api/training/del-evaluate-record`, { id: tempParam }).then(
            response => {
                this.setState({ deleteModalVisible: false });
                if (response.data.res === 1) {

                    message.success({
                        content: '成功',
                        className: 'custom-class',
                    });

                    // 重新获取列表数据
                    this.seacrhDataFun();
                }
                else {
                    message.error({
                        content: response.data.msg,
                        className: 'custom-class',
                    });
                }
            }
        );
    }

    // 关闭弹框-删除
    deleteCancel = () => {
        this.setState({ deleteModalVisible: false });
    }

    // 列表 每行数据选择checkbox
    onSelectChange = batchselectedKeys => {
        this.setState({ batchselectedKeys });
    };

    // 格式化参数
    formatParams = (seacrhParam) => {
        for (let key in seacrhParam) {
            seacrhParam[key] = seacrhParam[key] === undefined ? '' : seacrhParam[key];
        }
        return seacrhParam;
    }

    // 换页
    changePage = (pageNum, pageSize) => {
        this.seacrhDataFun({ page: pageNum });
    }

    // 查看报告
    viewReport = (data) => {
        if (data.report_type.toString() === '0') {
            window.open('/penguin/public/index.php/api/report/main?train_result_guid=' + data.train_result_guid, '_blank');
        }
        else if (data.report_type.toString() === '3') {
            window.open('/index.php/Home/Mreport/scaleReport/guid/' + data.train_result_guid, '_blank');
        }
        else if (data.report_type.toString() === '4') {
            // window.open("https://storage.mind360.com.cn/bg_media/penguin/report/" + data.train_result_guid + ".pdf", '_blank');
            window.open(baseConfig.httpUrl + `/api/report/career-report?course_user_guid=` + data.train_result_guid, '_blank');
        }
        else if (data.report_type.toString() === '5' || data.report_type.toString() === '6') {
            window.open('/index.php/Home/Mreport/attentionReportNoEEG/guid/' + data.train_result_guid, '_blank');
        }
        else if (data.report_type.toString() === '9') {
            window.open('/penguin/public/index.php/api/report/hypnosis-report?course_user_guid=' + data.train_result_guid, '_blank');
        }
        else if (data.report_type.toString() === '10') {
            window.open('/penguin/public/index.php/api/report/dong-tai-te-shu-jiao-yu?course_user_guid=' + data.train_result_guid, '_blank');
        }
        else {
            window.open('/index.php/Home/Mreport/attentionReport/guid/' + data.train_result_guid, '_blank');
        }
        // else if (data.report_type.toString() === '1') {
        //     window.open('/index.php/Home/Mreport/evaluationreport/guid/' + data.train_result_guid, '_blank');
        // }
        // else if (data.report_type.toString() === '2') {
        //     window.open('/index.php/Home/Mreport/attentionReport/guid/' + data.train_result_guid, '_blank');
        // }
    }

    /**
     * 下载 报告
     */
    downloadFileFun = () => {
        this.setState({ downloadVisible: true })
    }

    /**
     * 下载 确认
     */
    downloadOk = () => {

        let tempParam = {};
        Object.assign(tempParam, this.seacrhParam);
        tempParam.download = 1

        axios.get(baseConfig.httpUrl + `/api/training/evaluate-record`, { params: tempParam }).then(
            response => {
                if (response.data.res === 1) {

                    message.success(response.data.msg)
                    this.downloadCancel()
                }
                else {
                    message.error({
                        content: response.data.msg,
                    });
                }
            },
            error => { }
        )
    }

    /**
     * 下载 取消
     */
    downloadCancel = () => {
        this.setState({ downloadVisible: false })
    }

    render() {
        const { batchselectedKeys } = this.state;
        const rowSelection = {
            batchselectedKeys,
            onChange: this.onSelectChange,
        };

        return (
            <Card title="" style={{ minHeight: '100%' }}>

                {/* 删除测评记录 */}
                <Modal
                    className='modals'
                    title="删除测评记录"
                    visible={this.state.deleteModalVisible}
                    onOk={this.deleteOk}
                    onCancel={this.deleteCancel}
                    maskClosable={false}
                >
                    <p className='modalText'>{this.state.deleteModalText}</p>
                </Modal>

                <Modal
                    className='modals'
                    title="提示"
                    visible={this.state.downloadVisible}
                    onOk={this.downloadOk}
                    onCancel={this.downloadCancel}
                    maskClosable={false}
                >
                    <p className='modalText'>确定要批量下载报告吗</p>
                </Modal>

                {/* 搜索条件 */}
                <div>
                    <SearchCriteria
                        seacrhItems={this.state.searchItems}
                        batchShareFun={this.batchShare}
                        deleteFun={this.deleteUserData}
                        exportFun={this.exportDataFun}
                        seacrhFun={this.seacrhDataFun}
                        downloadFileFun={this.downloadFileFun}
                        updateSeacrhValue={this.updateSeacrhItems}
                        initialValueParam={{ gender: '0', course_id: '0', fname: this.state.fname, indicator: '0' }}
                    />
                </div>

                {/* 列表 */}
                <Table
                    dataSource={this.state.list}
                    pagination={false}
                    columns={this.columns}
                    rowSelection={rowSelection}
                    loading={this.state.tableLoading}
                    onChange={this.tableOnChange}
                >
                </Table>

                {/* 分页 */}
                <Pagination
                    onChange={this.changePage}
                    style={{ marginTop: 30 }}
                    defaultCurrent={1}
                    current={this.state.currentPage}
                    total={this.state.count}
                    hideOnSinglePage={true}
                    showQuickJumper={{
                        goButton: <span><span>&nbsp;&nbsp;&nbsp;&nbsp;</span> <Button type='primary'>Go</Button></span>,
                    }}
                    showTotal={(total, range) => `当前显示${range[0]}-${range[1]}条  共${total}条记录 `}
                    showSizeChanger={false}
                />
            </Card >
        )
    }
}


// 容器组件
const mapStateToProps = (state) => {
    return { breadcrumberData: state.breadcrumber, leftNavData: state.leftNav }
}

const mapDispatchToProps = (dispatch) => {
    return {
        add: (data) => { dispatch(addBreadcrumber(data)) }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportManage)
