const NationData = [
    { "value": '1', "title": "汉族" },
    { "value": '2', "title": "蒙古族" },
    { "value": '3', "title": "回族" },
    { "value": '4', "title": "藏族" },
    { "value": '5', "title": "维吾尔族" },
    { "value": '6', "title": "苗族" },
    { "value": '7', "title": "彝族" },
    { "value": '8', "title": "壮族" },
    { "value": '9', "title": "布依族" },
    { "value": '10', "title": "朝鲜族" },
    { "value": '11', "title": "满族" },
    { "value": '12', "title": "侗族" },
    { "value": '13', "title": "瑶族" },
    { "value": '14', "title": "白族" },
    { "value": '15', "title": "土家族" },
    { "value": '16', "title": "哈尼族" },
    { "value": '17', "title": "哈萨克族" },
    { "value": '18', "title": "傣族" },
    { "value": '19', "title": "黎族" },
    { "value": '20', "title": "傈僳族" },
    { "value": '21', "title": "佤族" },
    { "value": '22', "title": "畲族" },
    { "value": '23', "title": "高山族" },
    { "value": '24', "title": "拉祜族" },
    { "value": '25', "title": "水族" },
    { "value": '26', "title": "东乡族" },
    { "value": '27', "title": "纳西族" },
    { "value": '28', "title": "景颇族" },
    { "value": '29', "title": "柯尔克孜族" },
    { "value": '30', "title": "土族" },
    { "value": '31', "title": "达斡尔族" },
    { "value": '32', "title": "仫佬族" },
    { "value": '33', "title": "羌族" },
    { "value": '34', "title": " 布朗族" },
    { "value": '35', "title": " 撒拉族" },
    { "value": '36', "title": " 毛难族" },
    { "value": '37', "title": " 仡佬族" },
    { "value": '38', "title": " 锡伯族" },
    { "value": '39', "title": " 阿昌族" },
    { "value": '40', "title": " 普米族" },
    { "value": '41', "title": " 塔吉克族" },
    { "value": '42', "title": " 怒族" },
    { "value": '43', "title": " 乌孜别克族" },
    { "value": '44', "title": " 俄罗斯族" },
    { "value": '45', "title": " 鄂温克族" },
    { "value": '46', "title": " 崩龙族" },
    { "value": '47', "title": " 保安族" },
    { "value": '48', "title": " 裕固族" },
    { "value": '49', "title": " 京族" },
    { "value": '50', "title": " 塔塔尔族" },
    { "value": '51', "title": " 独龙族" },
    { "value": '52', "title": " 鄂伦春族" },
    { "value": '53', "title": " 赫哲族" },
    { "value": '54', "title": " 门巴族" },
    { "value": '55', "title": " 珞巴族" },
    { "value": '56', "title": " 基诺族" },
    { "value": '57', "title": " 其他" }
]


export default NationData