/* 
	1.该文件是用于创建一个为组件服务的reducer，reducer的本质就是一个函数
	2.reducer函数会接到两个参数，分别为：之前的状态(preState)，动作对象(action)
*/

const initState = {};

export default function adminReducer(preState = initState, action) {

    const { type, data } = action

    switch (type) {
        case 'adminTransfer':
            return data
        default:
            return preState
    }
}