import React, { Component } from 'react';
import { Button, Card, Table, Space, Pagination, message, Modal, Tooltip } from 'antd';
import { LinkOutlined, DisconnectOutlined } from '@ant-design/icons';
import { BATCH, SINGLE } from '../../config/constant';
import SearchCriteria from '../../components/SearchCriteria/SearchCriteria';
import axios from 'axios';
import baseConfig from '../../config/BaseConfig';
import { connect } from 'react-redux';
import { addBreadcrumber } from '../../redux/actions/Breadcrumber';
import NationData from '../../config/Nation';
import ShareEdit from '../ShareEdit/ShareEdit';

class ShareManage extends Component {

    constructor(props) {
        super(props);
        // 组件状态
        this.state = {
            list: [],
            currentPage: 1,
            count: 1,
            batchselectedKeys: [],
            singleSelecteKey: 0,
            deleteModalVisible: false,
            deleteModalText: '确定要删除用户【张三】吗？',
            deleteType: SINGLE,
            shareManageVisible: false,
            resetPwdSingleKey: 0,
            currnetRole: props.leftNavData.role,
            shareOperators: [],
            shareManageType: SINGLE,
            round: 0,
            tableLoading: false
        };
    }

    // 所有可选共享人
    allOperatorData = [];

    // 受测者id
    currentUserIds = [];

    // 当前受测者信息
    currentUser = {}

    // 搜索条件数值
    seacrhParam = {
        fname: '',
        num: '',
        phone: '',
        gender: '0',
        share_status: '0'
    };

    // 列表项
    columns = [
        {
            title: '编号',
            dataIndex: 'num',
            align: 'center',
            width: 180,
        },

        {
            title: '姓名',
            dataIndex: 'fname',
            align: 'center',
            width: 180,
        },
        {
            title: '性别',
            dataIndex: 'gender',
            align: 'center',
            width: 130,
            render: (gender) => { return Number(gender) === 1 ? '男' : Number(gender) === 2 ? '女' : '未知' },
        },
        {
            title: '民族',
            dataIndex: 'nation',
            align: 'center',
            width: 130,
            render: (nation) => { return nation === null ? '未知' : this.nationText(nation)[0].title },
        },
        {
            title: '生日',
            dataIndex: 'birthday',
            align: 'center',
            width: 180,
            render: (birthday) => { return birthday === null ? '' : birthday.substr(0, 10) },
        },
        {
            title: '共享状态',
            dataIndex: 'share_user',
            align: 'center',
            width: 130,
            render: (share_user) => { return share_user.length === 0 ? '未共享' : '已共享' },
        },

        {
            title: '共享用户',
            dataIndex: 'share_user',
            align: 'center',
            width: 130,
            render: (share_user) => { return this.shareUser(share_user) },
        },
        {
            title: '操作',
            dataIndex: 'operator',
            align: 'center',
            width: 150,
            render: (text, record) => (
                <Space size="middle">
                    <Tooltip title="共享管理">
                        <Button shape="circle" onClick={() => { this.singleShare(record) }}><LinkOutlined /></Button>
                    </Tooltip>
                    <Tooltip title="取消共享">
                        <Button shape="circle" onClick={() => { this.deleteUserData([record]) }}><DisconnectOutlined /></Button>
                    </Tooltip>
                </Space>
            )
        },
    ];

    // 搜索条件组件初始数据
    seacrhItemsData = [
        { title: '姓名', inputType: 'input', seacrhType: 'fname' },
        { title: '编号', inputType: 'input', seacrhType: 'num' },
        { title: '联系电话', inputType: 'input', seacrhType: 'phone' },
        { title: '性别', inputType: 'select', seacrhType: 'gender', children: [{ title: '全部', value: '0' }, { title: '男', value: '1' }, { title: '女', value: '2' }] },
        { title: '共享状态', inputType: 'select', seacrhType: 'share_status', children: [{ title: '全部', value: '0' }, { title: '已共享', value: '1' }, { title: '未共享', value: '2' }] },
        // { title: '', inputType: 'buttons', seacrhType: '', span: 6, children: [{ title: '搜索', key: 'submit' }, { title: '批量共享', key: 'batchShare' }, { title: '取消共享', key: 'delete' }, { title: '导出', key: 'export' }] },
        { title: '', inputType: 'buttons', seacrhType: '', span: 6, children: [{ title: '批量共享', key: 'batchShare' }, { title: '取消共享', key: 'delete' }, { title: '导出', key: 'export' }] },
    ];

    // 组件挂载
    componentDidMount() {
        this.seacrhDataFun({ page: 1 });
        this.getShareOperators();
    }

    // 共享用户
    shareUser = (data) => {
        let temp = '';
        for (let i = 0; i < data.length; i++) {
            temp += data[i].fname + ',';
        }
        return temp;
    }

    // 民族显示
    nationText = (nationValue) => {
        return NationData.filter((tempObj) => {
            return tempObj.value === nationValue + '';
        })
    }

    // 获取列表数据
    seacrhDataFun = (data = null) => {
        let tempParam = {};
        Object.assign(tempParam, this.seacrhParam);
        tempParam.page = this.state.currentPage;

        if (data) {
            tempParam.page = data.page;
        }

        this.setState({ tableLoading: true });
        axios.get(baseConfig.httpUrl + `/api/user/get-share-list`, { params: tempParam }).then(
            response => {
                if (response.data.res === 1) {
                    const { data, page, count } = response.data;

                    data.map((dataObj) => {
                        return dataObj.key = dataObj.id
                    });

                    this.setState({
                        list: [...data],
                        currentPage: page * 1,
                        count: count,
                        batchselectedKeys: [],
                        tableLoading: false
                    });
                }
                else {
                    this.setState({
                        list: [],
                        currentPage: 1,
                        count: 1,
                        batchselectedKeys: [],
                        tableLoading: false
                    });
                    message.error({
                        content: response.data.msg,
                        className: 'custom-class',
                    });
                }
            },
            error => { }
        );
    }

    // 批量共享
    batchShare = () => {
        this.currentUserIds = [...this.state.batchselectedKeys];
        if (this.currentUserIds.length < 1) {
            message.warning('请选择批量共享的受测者');
            return false;
        }
        this.shareFun(BATCH);
    }

    // 单个共享
    singleShare = (data) => {
        this.currentUserIds = [data.id];
        this.shareFun(SINGLE, data);
    }

    // 共享
    shareFun = (type, data = null) => {
        if (data !== null) {
            Object.assign(this.currentUser, data)
        }

        this.setState({ shareManageVisible: true, shareManageType: type, round: this.state.round + 1 });
    }

    // 跟新搜索条件数值
    updateSeacrhItems = (seacrhData) => {
        let tempObj = this.formatParams(seacrhData);
        Object.assign(this.seacrhParam, tempObj);
    }

    // 导出
    exportDataFun = () => {
        let exportUrl = baseConfig.httpUrl + '/api/user/get-share-list?';
        for (let key in this.seacrhParam) {
            if (this.seacrhParam[key] !== undefined) {
                exportUrl += key + '=' + this.seacrhParam[key] + '&'
            }
        }
        exportUrl += 'export=1'
        window.open(exportUrl, '_blank')
    }

    // 取消共享
    deleteUserData = (data) => {
        const { batchselectedKeys } = this.state;
        
        let tempdeleteModalText = '';
        if (data) {
            tempdeleteModalText = `确定要取消共享受测者 【${data[0].fname}】 吗？`;
            this.setState({ deleteModalVisible: true, deleteModalText: tempdeleteModalText, deleteType: SINGLE, singleSelecteKey: data[0].id });
        }
        else {
            if (batchselectedKeys.length < 1) {
                message.warning({
                    content: '请选择要取消共享的受测者',
                    duration: 3
                });
            }
            else {
                tempdeleteModalText = `确定要取消所选受测者的共享吗？`;
                this.setState({ deleteModalVisible: true, deleteModalText: tempdeleteModalText, deleteType: BATCH });
            }
        }
    }

    // 点击确定-取消共享
    deleteOk = () => {
        let tempParam = this.state.deleteType === SINGLE ? [this.state.singleSelecteKey] : this.state.batchselectedKeys;
        axios.post(baseConfig.httpUrl + `/api/user/create-share`, { tester: tempParam, to_who: [], clear_before_create: 1 }).then(
            response => {
                this.setState({ deleteModalVisible: false });
                if (response.data.res === 1) {

                    message.success({
                        content: '成功',
                        className: 'custom-class',
                    });

                    // 重新获取列表数据
                    this.seacrhDataFun();
                }
                else {
                    message.error({
                        content: response.data.msg,
                        className: 'custom-class',
                    });
                }
            }
        );
    }

    // 关闭弹框-取消共享
    deleteCancel = () => {
        this.setState({ deleteModalVisible: false });
    }

    // 关闭弹框-共享管理
    shareManageCancel = () => {
        this.setState({ shareManageVisible: false })
    }

    // 列表 每行数据选择checkbox
    onSelectChange = batchselectedKeys => {
        this.setState({ batchselectedKeys });
    };

    // 新增用户
    addUser = () => { }

    // 格式化参数
    formatParams = (seacrhParam) => {
        for (let key in seacrhParam) {
            seacrhParam[key] = seacrhParam[key] === undefined ? '' : seacrhParam[key];
        }
        return seacrhParam;
    }

    // 换页
    changePage = (pageNum, pageSize) => {
        this.seacrhDataFun({ page: pageNum });
    }

    // 搜索条件过滤
    remakeSeacrhData = () => {
        if (this.state.currnetRole && this.state.currnetRole === 8) {
            let buttons = this.seacrhItemsData[this.seacrhItemsData.length - 1].children;
            for (let i = 0; i < buttons.length; i++) {
                if (buttons[i].key === 'add') {
                    buttons.splice(i, 1);
                }
            }
            for (let i = 0; i < buttons.length; i++) {
                if (buttons[i].key === 'delete') {
                    buttons.splice(i, 1);
                }
            }
        }
    }

    // 获取共享人列表
    getShareOperators = () => {
        axios.get(baseConfig.httpUrl + `/api/user/get-operator-list`, { params: { role: '0', page: '0' } }).then(
            response => {
                if (response.data.res === 1) {
                    this.allOperatorData = [...response.data.data];
                }
                else {

                }
            }
        )
    }

    render() {
        this.remakeSeacrhData();

        const { batchselectedKeys } = this.state;
        const rowSelection = {
            batchselectedKeys,
            onChange: this.onSelectChange,
        };

        return (
            <Card title="" style={{ minHeight: '100%' }}>

                {/* 取消共享 */}
                <Modal
                    className='modals'
                    title="取消共享"
                    visible={this.state.deleteModalVisible}
                    onOk={this.deleteOk}
                    onCancel={this.deleteCancel}
                    maskClosable={false}
                >
                    <p className='modalText'>{this.state.deleteModalText}</p>
                </Modal>

                {/* 共享管理 */}
                <Modal
                    className='modals modals-noButton'
                    title="共享管理"
                    visible={this.state.shareManageVisible}
                    onCancel={this.shareManageCancel}
                    maskClosable={false}
                    width={700}
                >
                    <ShareEdit
                        key={this.state.round}
                        shareManageType={this.state.shareManageType}
                        allOperatorData={this.allOperatorData}
                        currentUser={this.currentUser}
                        currentUserIds={this.currentUserIds}
                        closeModal={this.shareManageCancel}
                        seacrhFun={this.seacrhDataFun}
                    />
                </Modal>

                {/* 搜索条件 */}
                <div>
                    <SearchCriteria
                        seacrhItems={this.seacrhItemsData}
                        batchShareFun={this.batchShare}
                        deleteFun={this.deleteUserData}
                        exportFun={this.exportDataFun}
                        seacrhFun={this.seacrhDataFun}
                        updateSeacrhValue={this.updateSeacrhItems}
                        initialValueParam={{ gender: '0', share_status: '0' }}
                    />
                </div>

                {/* 列表 */}
                <Table
                    dataSource={this.state.list}
                    pagination={false}
                    columns={this.columns}
                    rowSelection={rowSelection}
                    loading={this.state.tableLoading}
                >
                </Table>

                {/* 分页 */}
                <Pagination
                    onChange={this.changePage}
                    style={{ marginTop: 30 }}
                    defaultCurrent={1}
                    current={this.state.currentPage}
                    total={this.state.count}
                    hideOnSinglePage={true}
                    showQuickJumper={{
                        goButton: <span><span>&nbsp;&nbsp;&nbsp;&nbsp;</span> <Button type='primary'>Go</Button></span>,
                    }}
                    showTotal={(total, range) => `当前显示${range[0]}-${range[1]}条  共${total}条记录 `}
                    showSizeChanger={false}
                />
            </Card >
        )
    }
}

// 容器组件
const mapStateToProps = (state) => {
    return { breadcrumberData: state.breadcrumber, leftNavData: state.leftNav }
}

const mapDispatchToProps = (dispatch) => {
    return {
        add: (data) => { dispatch(addBreadcrumber(data)) }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ShareManage)