import React, { Component } from 'react';
import { Button, Card, message, Form, Input, Row, Col, Select } from 'antd';
import axios from 'axios';
import baseConfig from '../../config/BaseConfig';
import { FORM_INPUT, FORM_SELECT } from '../../config/constant';
import './PersonalData.less'

const { Option } = Select;

const organInfoLayout = {
    labelCol: {
        span: 4,
    },
    wrapperCol: {
        span: 8,
    },
};

export default class PersonalData extends Component {
    personInfoRef = React.createRef();
    accountInfoRef = React.createRef();

    state = {
        editType: true,
        editTypeAccount: true,
        editBtn: 'inline-block',
        saveBtn: 'none',
    }

    componentDidMount() {
        this.personInfoData()
    }

    // 表单初始项-账号资料
    accountInfoFormData = [
        {
            title: '登录账号',
            inputType: FORM_INPUT,
            seacrhType: 'uname',
            value: this.state.personUname,
        },
        {
            title: '编\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0号',
            inputType: FORM_INPUT,
            seacrhType: 'num',
            value: this.state.personNum,
        },
        {
            title: '角\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0色',
            inputType: FORM_INPUT,
            seacrhType: 'role',
            value: this.state.personRoleName,
        },
    ];

    // 表单初始项-个人资料
    personInfoFormData = [
        {
            title: '姓\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0名',
            inputType: FORM_INPUT,
            seacrhType: 'fname',
            value: this.state.personFname,
            rules: []
        },
        {
            title: '性\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0别',
            inputType: FORM_SELECT,
            seacrhType: 'gender',
            value: this.state.personGender,
            children: [
                { title: '男', value: '1' },
                { title: '女', value: '2' }
            ],
            rules: []
        },
        {
            title: '联系电话',
            inputType: FORM_INPUT,
            seacrhType: 'phone',
            value: this.state.personPhone,
            rules: [
                {
                    pattern: new RegExp(/^1\d{10}$/, "g"),
                    message: '输入联系电话格式不正确',
                },
            ]
        },
        {
            title: '职\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0务',
            inputType: FORM_INPUT,
            seacrhType: 'duty',
            value: this.state.personDuty,
            rules: []
        },
    ];

    // 获取个人信息
    personInfoData = () => {
        axios.get(baseConfig.httpUrl + '/api/user/get-user-info').then(
            response => {
                if (this.personInfoRef.current || this.accountInfoRef.current) {
                    if (response.data.res === 1) {
                        console.log(response.data);

                        this.personInfoRef.current.setFieldsValue({
                            fname: response.data.user.fname,
                            gender: response.data.user.gender ? response.data.user.gender.toString() : '',
                            phone: response.data.user.phone,
                            duty: response.data.user.duty,
                        });

                        this.accountInfoRef.current.setFieldsValue({
                            uname: response.data.user.uname,
                            num: response.data.user.num,
                            role: response.data.user.role_name,
                        });

                        this.setState({
                            personFname: response.data.user.fname,
                            personUname: response.data.user.uname,
                            personNum: response.data.user.num,
                            personRole: response.data.user.role,
                            personRoleName: response.data.user.role_name,
                            personGender: response.data.user.gender,
                            personPhone: response.data.user.phone,
                            personDuty: response.data.user.duty,
                        });
                    }
                    else {
                        message.error(response.data.msg)
                    }
                }
            }
        )
    }

    // 创建表单
    initFrom = (formData, formType) => {
        const children = [];
        for (let i = 0; i < formData.length; i++) {
            children.push(
                <Col span={12} key={i}>
                    <Form.Item
                        name={formData[i].seacrhType}
                        label={formData[i].title}
                        rules={formData[i].rules}
                    >
                        {this.createFromItem(formData[i], formType)}
                    </Form.Item>
                </Col>,
            );
        }

        return children;
    };

    // 创建表单item
    createFromItem = (itemData, formType) => {
        if (itemData.inputType === FORM_INPUT) {
            if (formType === 'account') {
                return <Input disabled={this.state.editTypeAccount} />
            }
            else if (formType === 'person') {
                return <Input placeholder={'请输入' + itemData.title.replace(/\s*/g, "")} disabled={this.state.editType} />
            }
        }
        else if (itemData.inputType === FORM_SELECT) {
            return (
                <Select style={{ width: '100%' }} disabled={this.state.editType}>
                    {
                        this.createSelectOption(itemData.children)
                    }
                </Select>
            )
        }
    }

    // 搜索条件select中option
    createSelectOption = (selectData) => {
        const options = [];
        for (let j = 0; j < selectData.length; j++) {
            options.push(
                <Option key={selectData[j].value} value={selectData[j].value}>{selectData[j].title}</Option>
            )
        }
        return options;
    }

    // 编辑
    editFun = () => {
        this.setState({
            editType: false,
            editBtn: 'none',
            saveBtn: 'inline-block',
        });
    }

    // 保存
    saveFun = () => {
        this.personInfoRef.current.submit();
    }

    // 
    savePersonInfo = (values) => {
        axios.post(baseConfig.httpUrl + '/api/user/update-user-info', values).then(
            response => {
                if (response.data.res === 1) {
                    message.success(response.data.msg);
                    this.setState({
                        editType: true,
                        editBtn: 'inline-block',
                        saveBtn: 'none',
                    })
                } else {
                    message.error(response.data.msg);
                }
            }
        )
    }


    render() {
        return (
            <Card title="基本资料" className='baseInfo'>

                {/* 帐号信息 */}
                <div className="baseInfo-module person-model" style={{ marginBottom: 50 }}>
                    <div className='module-header'>
                        <div className='module-header-colorBlock'></div>
                        <div className='module-header-title'>帐号信息</div>
                    </div>
                    <Form
                        {...organInfoLayout}
                        ref={this.accountInfoRef}
                        name="account-Info"
                        className='module-form'
                        initialValues={{
                            uname: '',
                            num: '',
                            role: '',
                        }}
                    >
                        <Row>
                            {this.initFrom(this.accountInfoFormData, 'account')}
                        </Row>
                    </Form>
                </div>

                {/* 个人资料 */}
                <div className="baseInfo-module">
                    <div className='module-header'>
                        <div className='module-header-colorBlock'></div>
                        <div className='module-header-title'>个人资料</div>
                    </div>
                    <Form
                        name="person-Info"
                        {...organInfoLayout}
                        ref={this.personInfoRef}
                        onFinish={this.savePersonInfo}
                        className='module-form'
                        initialValues={{
                            fname: '',
                            gender: '1',
                            phone: '',
                            duty: '',
                        }}
                    >
                        <Row>
                            {this.initFrom(this.personInfoFormData, 'person')}
                        </Row>
                        <Row>
                            <Col span={6} offset={2} style={{ textAlign: 'left' }}>
                                <Button type="primary" htmlType="button" style={{ display: this.state.editBtn }} onClick={this.editFun}> 编辑 </Button>
                                <Button type="primary" htmlType="button" style={{ display: this.state.saveBtn }} onClick={this.saveFun}> 保存 </Button>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </Card >
        )
    }
}
