import React, { Component } from 'react';
import { Button, Card, Table, Space, Pagination, message, Tooltip } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import axios from 'axios';
import baseConfig from '../../config/BaseConfig';
import { connect } from 'react-redux';

class MyDownload extends Component {

    state = {
        list: [],
        currentPage: 1,
        count: 1,
        tableLoading: false,
    }

    // 列表项
    columns = [
        {
            title: '操作者',
            dataIndex: 'creator',
            align: 'center',
            width: 100,
        },
        {
            title: '创建时间',
            dataIndex: 'created_at',
            align: 'center',
            width: 150,
        },
        {
            title: '完成时间',
            dataIndex: 'finished_at',
            align: 'center',
            width: 150,
        },
        {
            title: '任务状态',
            dataIndex: 'status',
            align: 'center',
            width: 100,
            render: (status) => { return Number(status) === 1 ? '进行中' : Number(status) === 2 ? '已完成' : '未开始' },
        },
        {
            title: '任务进度',
            dataIndex: 'progress',
            align: 'center',
            width: 100,
            render: (progress) => {
                return progress ? progress + '%' : ''
            }
        },
        {
            title: '操作',
            dataIndex: 'operator',
            align: 'center',
            width: 100,
            render: (text, record) => (
                <Space size="middle">
                    <Tooltip title="下载文件">
                        <Button shape="circle" disabled={Number(record.status) === 2 ? false : true} onClick={() => { this.download(record) }}><DownloadOutlined /></Button>
                    </Tooltip>
                </Space>
            )
        },
    ]

    // 组件挂载
    componentDidMount() {
        this.getTaskList({ page: 1 })
    }

    /**
     * 获取 任务列表
     * @param {*} data 
     */
    getTaskList = (data = null) => {

        let tempPage = this.state.currentPage
        if (data) {
            tempPage = data.page
        }

        this.setState({ tableLoading: true })
        axios.get(baseConfig.httpUrl + `/api/task/list`, { params: { page: tempPage } }).then(
            response => {
                if (response.data.res === 1) {

                    const { list, page, count } = response.data

                    this.setState({
                        list: list,
                        count: count,
                        currentPage: page * 1,
                        tableLoading: false
                    })
                }
                else {
                    this.setState({
                        list: [],
                        count: 0,
                        currentPage: 1,
                        tableLoading: false
                    })
                    message.error(response.data.msg)
                }
            }
        )
    }

    /**
     * 下载 
     * @param {*} data 
     */
    download = (data) => {
        window.open(data.result, '_blank')
    }

    // 换页
    changePage = (pageNum, pageSize) => {
        this.getTaskList({ page: pageNum })
    }

    render() {
        return (
            <Card style={{ minHeight: '100%' }} className='MyDownloadHg'>

                {/* 列表 */}
                <Table
                    dataSource={this.state.list}
                    pagination={false}
                    columns={this.columns}
                    loading={this.state.tableLoading}
                >
                </Table>

                {/* 分页 */}
                <Pagination
                    onChange={this.changePage}
                    style={{ marginTop: 30 }}
                    defaultCurrent={1}
                    current={this.state.currentPage}
                    total={this.state.count}
                    hideOnSinglePage={true}
                    showQuickJumper={{
                        goButton: <span><span>&nbsp;&nbsp;&nbsp;&nbsp;</span> <Button type='primary'>Go</Button></span>,
                    }}
                    showTotal={(total, range) => `当前显示${range[0]}-${range[1]}条  共${total}条记录 `}
                    showSizeChanger={false}
                />
            </Card >
        )
    }
}


// 容器组件
const mapStateToProps = (state) => {
    return {}
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(MyDownload)