import React, { Component } from 'react'
import { Card, Row, Col, Button, Modal, message, DatePicker } from 'antd'
import axios from 'axios';
import baseConfig from '../../config/BaseConfig'
import './index.less'

const { RangePicker } = DatePicker

export default class BoardSetting extends Component {

  state = {
    dateRange: '-',
    editDateRangeVisible: false,
    cleanDateRangeVisible: false,
    startTime: '',
    endTime: ''
  }

  // 组件挂载
  componentDidMount() {
    this.dateRangeInfo()
  }

  /**
   * 获取 时间范围
   */
  dateRangeInfo = () => {
    axios.get(baseConfig.httpUrl + '/api/kanban-config/config').then(
      response => {
        if (response.data.res === 1) {
          const { start_time, end_time } = response.data.data
          this.setState({ startTime: start_time.substring(0, 10), endTime: end_time.substring(0, 10), dateRange: start_time.substring(0, 10) + ' - ' + end_time.substring(0, 10) })
        }
        else {
          message.error(response.data.msg)
        }
      }
    )
  }

  // 关闭弹框
  modalCancel = () => {
    this.setState({
      editDateRangeVisible: false,
      cleanDateRangeVisible: false,
    });
  }

  /**
   * 编辑 时间范围
   */
  editDateRange = () => {
    this.setState({ editDateRangeVisible: true })
  }

  /**
   * 关闭 编辑 时间范围
   */
  cancelEditDateRange = () => {
    this.setState({ editDateRangeVisible: false })
  }

  /**
   * 清楚 时间范围
   */
  cleanDateRange = () => {
    this.setState({ cleanDateRangeVisible: true })
  }

  /**
   * 提交数据
   */
  comfirmEditDateRange = () => {
    const { startTime, endTime } = this.state
    this.upDateDateRange(startTime, endTime)
  }

  /**
   * 选择 时间范围
   * @param {*} dates 
   * @param {*} dateStrings 
   */
  onChangeDateRange = (dates, dateStrings) => {
    this.setState({ startTime: dateStrings[0], endTime: dateStrings[1] })
  }

  /**
   * 更新数据
   */
  upDateDateRange = (startTime, endTime) => {
    let param = {}
    param.start_time = startTime
    param.end_time = endTime
    axios.post(baseConfig.httpUrl + '/api/kanban-config/update', param).then(
      response => {
        if (response.data.res === 1) {
          this.setState({ dateRange: param.start_time + ' - ' + param.end_time, startTime: startTime, endTime: endTime })
          this.modalCancel()
          this.dateRangeInfo()
        }
        else {
          message.error(response.data.msg)
        }
      }
    );
  }

  /**
   * 确认 清除 范围
   */
  comfirmCleanDateRange = () => {
    this.upDateDateRange('1970-01-01', '2099-01-01')
  }

  render() {

    const { dateRange, editDateRangeVisible, cleanDateRangeVisible } = this.state

    return (
      <Card title="看板设置" className='BoardSetting' style={{ minHeight: '100%' }}>

        <Row style={{ marginTop: 25 }}>
          <Col style={{ textAlign: 'right', lineHeight: '32px' }} span={2}>时间范围：</Col>
          <Col span={6}> <div style={{ lineHeight: '32px' }}>{dateRange}</div> </Col>
          <Col span={4}>
            <Button type="primary" style={{ marginLeft: 15 }} onClick={this.editDateRange} >编辑</Button>
            <Button type="primary" style={{ marginLeft: 15 }} onClick={this.cleanDateRange} >清除</Button>
          </Col>
        </Row>

        {/* 编辑时间范围 */}
        <Modal
          className='modals BoardSetting'
          title="编辑时间范围"
          visible={editDateRangeVisible}
          onCancel={this.modalCancel}
          maskClosable={false}
          destroyOnClose={true}
        >
          <Row>
            <Col span={6}><div style={{ textAlign: 'right', lineHeight: '32px' }}>时间范围：</div></Col>
            <Col span={14}><RangePicker style={{ width: '100%' }} onChange={this.onChangeDateRange} /></Col>
          </Row>
          <Row style={{ marginTop: 20 }} >
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', width: '100%' }}>
              <Button type="primary" style={{ marginLeft: 15 }} onClick={this.comfirmEditDateRange}>确定</Button>
            </div>
          </Row>
        </Modal>

        {/* 清除时间范围 */}
        <Modal
          className='modals BoardSetting'
          title="清除时间范围"
          visible={cleanDateRangeVisible}
          onCancel={this.modalCancel}
          maskClosable={false}
          destroyOnClose={true}
        >
          <div style={{ textAlign: 'center', lineHeight: '32px' }}>是否要清除数据看板时间范围</div>

          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', width: '100%', marginTop: '20px' }}>
            <Button type="primary" style={{ marginLeft: 15 }} onClick={this.comfirmCleanDateRange}>确定</Button>
          </div>
        </Modal>

      </Card >
    )
  }
}
