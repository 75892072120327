import React, { Component } from 'react';
import { Button, Card, Table, Space, Pagination, message, Tooltip } from 'antd';
import { SnippetsOutlined, } from '@ant-design/icons';
import SearchCriteria from '../../components/SearchCriteria/SearchCriteria';
import axios from 'axios';
import baseConfig from '../../config/BaseConfig';
import { connect } from 'react-redux';
import { addBreadcrumber } from '../../redux/actions/Breadcrumber';
import moment from 'moment';

const circleGreen = <div style={{ width: '1rem', height: '1rem', borderRadius: '1rem', backgroundColor: 'green', display: 'inline-block' }}></div>
const circleRed = <div style={{ width: '1rem', height: '1rem', borderRadius: '1rem', backgroundColor: 'red', display: 'inline-block' }}></div>

/**
 * 预警列表
 */
class EarlyWarning extends Component {

    constructor(props) {
        super(props);

        // 组件状态
        this.state = {
            list: [],
            currentPage: 1,
            count: 1,
            tableLoading: false,
            searchItems: [],
        };
    }

    // 搜索条件数值
    seacrhParam = {
        full_name: '',
        date_range: [],
        start_time: '',
        end_time: '',
    };

    // 列表项
    columns = [
        {
            title: '姓名',
            dataIndex: 'full_name',
            align: 'center',
            width: 180,
        },
        {
            title: '性别',
            dataIndex: 'gender',
            align: 'center',
            width: 100,
            render: (gender) => { return Number(gender) === 1 ? '男' : Number(gender) === 2 ? '女' : '未知' },
        },
        {
            title: '出生年月',
            dataIndex: 'birthday',
            align: 'center',
            width: 200,
        },
        {
            title: '测评时间',
            dataIndex: 'end_time',
            align: 'center',
            width: 200,
        },
        {
            title: '年级',
            dataIndex: 'grade',
            align: 'center',
            width: 120,
        },
        {
            title: '班级',
            dataIndex: 'group_name',
            align: 'center',
            width: 120,
        },
        {
            title: '焦虑指标',
            dataIndex: 'anxiety',
            width: 150,
            align: 'center',render: (anxiety) => { return anxiety == 1 ? <div style={{ color: 'green' }}>{circleGreen} 正常</div> : <div style={{ color: 'red' }}>{circleRed} 异常</div> }
        },
        {
            title: '睡眠指标',
            dataIndex: 'sleep',
            align: 'center',
            width: 150,
            render: (sleep) => { return sleep == 1 ? <div style={{ color: 'green' }}>{circleGreen} 正常</div> : <div style={{ color: 'red' }}>{circleRed} 异常</div> }
        },
        {
            title: '抑郁指标',
            dataIndex: 'depression',
            width: 150,
            align: 'center',render: (depression) => { return depression == 1 ? <div style={{ color: 'green' }}>{circleGreen} 正常</div> : <div style={{ color: 'red' }}>{circleRed} 异常</div> }
        },
        {
            title: '操作',
            dataIndex: 'operator',
            align: 'center',
            width: 150,
            render: (text, record) => (
                <Space size="middle">
                    <Tooltip title="报告">
                        <Button shape="circle" onClick={() => { this.viewReport(record) }}><SnippetsOutlined /></Button>
                    </Tooltip>
                </Space>
            )
        },
    ];

    // 搜索条件组件初始数据
    seacrhItemsData = [
        { title: '姓名', inputType: 'input', seacrhType: 'full_name' },
        { title: '测评时间', inputType: 'date_range', seacrhType: 'date_range', span: 6 },
    ];

    // 组件挂载
    componentDidMount() {
        this.seacrhDataFun({ page: 1 })
        this.setState({ searchItems: this.seacrhItemsData });
    }

    // 获取列表数据
    seacrhDataFun = (data = null) => {

        let tempParam = {};
        Object.assign(tempParam, this.seacrhParam);
        tempParam.page = this.state.currentPage;

        if (data) {
            if (data.page) {
                tempParam.page = data.page;
            }
        }

        if (tempParam.date_range) {
            delete tempParam.date_range
        }

        this.setState({ tableLoading: true });
        axios.get(baseConfig.httpUrl + `/api/early-warning/list`, { params: tempParam }).then(
            response => {
                if (response.data.res === 1) {
                    const { list, page, count } = response.data;

                    list.map((dataObj) => {
                        return dataObj.key = dataObj.id
                    });

                    this.setState({
                        list: [...list],
                        currentPage: page * 1,
                        count: count,
                        tableLoading: false
                    });
                }
                else {
                    this.setState({
                        list: [],
                        currentPage: 1,
                        count: 1,
                        tableLoading: false
                    });
                    message.error({
                        content: response.data.msg,
                        className: 'custom-class',
                    });
                }
            },
            error => { }
        )
    }

    // 跟新搜索条件数值
    updateSeacrhItems = (seacrhData) => {
        
        let tempObj = this.formatParams(seacrhData);

        if (tempObj.date_range && tempObj.date_range.length === 2) {
            tempObj.start_time = moment(tempObj.date_range[0]).format('YYYY-MM-DD');
            tempObj.end_time = moment(tempObj.date_range[1]).format('YYYY-MM-DD');
        }
        else {
            tempObj.start_time = '';
            tempObj.end_time = '';
        }

        // console.log('updateSeacrhItems', tempObj)
        Object.assign(this.seacrhParam, tempObj);
    }

    // 格式化参数
    formatParams = (seacrhParam) => {
        for (let key in seacrhParam) {
            seacrhParam[key] = seacrhParam[key] === undefined ? '' : seacrhParam[key];
        }
        return seacrhParam;
    }

    // 换页
    changePage = (pageNum, pageSize) => {
        this.seacrhDataFun({ page: pageNum });
    }

    // 查看报告
    viewReport = (data) => {
        if (data.report_type.toString() === '0') {
            window.open('/penguin/public/index.php/api/report/main?train_result_guid=' + data.course_user_guid, '_blank');
        }
        else if (data.report_type.toString() === '3') {
            window.open('/index.php/Home/Mreport/scaleReport/guid/' + data.course_user_guid, '_blank');
        }
        else if (data.report_type.toString() === '4') {
            window.open(baseConfig.httpUrl + `/api/report/career-report?course_user_guid=` + data.course_user_guid, '_blank');
        }
        else if (data.report_type.toString() === '5' || data.report_type.toString() === '6') {
            window.open('/index.php/Home/Mreport/attentionReportNoEEG/guid/' + data.course_user_guid, '_blank');
        }
        else if (data.report_type.toString() === '9') {
            window.open('/penguin/public/index.php/api/report/hypnosis-report?course_user_guid=' + data.course_user_guid, '_blank');
        }
        else if (data.report_type.toString() === '10') {
            window.open('/penguin/public/index.php/api/report/dong-tai-te-shu-jiao-yu?course_user_guid=' + data.course_user_guid, '_blank');
        }
        else {
            window.open('/index.php/Home/Mreport/attentionReport/guid/' + data.course_user_guid, '_blank');
        }
    }

    render() {

        return (
            <Card title="" style={{ minHeight: '100%' }}>

                {/* 搜索条件 */}
                <div>
                    <SearchCriteria
                        seacrhItems={this.state.searchItems}
                        batchShareFun={this.batchShare}
                        deleteFun={this.deleteUserData}
                        exportFun={this.exportDataFun}
                        seacrhFun={this.seacrhDataFun}
                        downloadFileFun={this.downloadFileFun}
                        updateSeacrhValue={this.updateSeacrhItems}
                        initialValueParam={{ gender: '0', course_id: '0', fname: this.state.fname, indicator: '0' }}
                    />
                </div>

                {/* 列表 */}
                <Table
                    dataSource={this.state.list}
                    pagination={false}
                    columns={this.columns}
                    loading={this.state.tableLoading}
                >
                </Table>

                {/* 分页 */}
                <Pagination
                    onChange={this.changePage}
                    style={{ marginTop: 30 }}
                    defaultCurrent={1}
                    current={this.state.currentPage}
                    total={this.state.count}
                    hideOnSinglePage={true}
                    showQuickJumper={{
                        goButton: <span><span>&nbsp;&nbsp;&nbsp;&nbsp;</span> <Button type='primary'>Go</Button></span>,
                    }}
                    showTotal={(total, range) => `当前显示${range[0]}-${range[1]}条  共${total}条记录 `}
                    showSizeChanger={false}
                />
            </Card >
        )
    }
}

// 容器组件
const mapStateToProps = (state) => {
    return { breadcrumberData: state.breadcrumber, leftNavData: state.leftNav }
}

const mapDispatchToProps = (dispatch) => {
    return {
        add: (data) => { dispatch(addBreadcrumber(data)) }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EarlyWarning)
