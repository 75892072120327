/* 
    1.该文件是用于创建一个为组件服务的reducer，reducer的本质就是一个函数
    2.reducer函数会接到两个参数，分别为：之前的状态(preState)，动作对象(action)
*/
import { ADD_BREADCRUMBER, REDUCE_BREADCRUMBER, REPLACE_BREADCRUMBER } from '../../config/constant'

const initState = [{ path: '/organmanage', name: '机构管理', grade: 1 }, { path: '/usermanage', name: '用户管理', grade: 2 }];

export default function breadcrumberReducer(preState = initState, action) {
    const { type, data } = action

    // console.log('breadcrumberReducer', preState, data)

    switch (type) {
        case REPLACE_BREADCRUMBER:
            return data
        case ADD_BREADCRUMBER:
            return addBreadcrumber(preState, data)
        case REDUCE_BREADCRUMBER:
            return reduceBreadcrumber(preState, data)
        default:
            return preState
    }
}

// 增加
const addBreadcrumber = (preState, data) => {
    data.grade = preState.length + 1
    return [...preState, data]
}

// 减少
const reduceBreadcrumber = (preState, data) => {
    return preState.filter((tempObj) => {
        return tempObj.grade <= data
    })
}