import React, { Component } from 'react';
import { Card, Button, Modal } from 'antd';
import { Prompt } from 'react-router-dom';
// import axios from 'axios';
// import baseConfig from '../../config/BaseConfig';
import './CreateEvaluate.less';
import baseConfig from '../../config/BaseConfig';
import { connect } from 'react-redux';
import { reduceBreadcrumber, replaceBreadcrumber } from '../../redux/actions/Breadcrumber';

/* 创建测评-暂时弃用 */
class CreateEvaluate extends Component {
    iframeRef = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            course_id: props.location.state.course_id,
            user_id: props.location.state.user_id,
            iframeUrl: props.location.state.url,
            stopModalVisible: false,
            stopModalText: '',
            endModalVisible: false,
        };
    }

    // 加载组件
    componentDidMount() {
        //监听message事件
        window.addEventListener("message", this.receiveMessageFromIframe);
    }

    // 卸载组件
    componentWillUnmount() {
        // 移除监听
        window.removeEventListener("message", this.receiveMessageFromIframe);
    }

    // 接收iframes调用
    receiveMessageFromIframe = (event) => {
        if (event !== undefined && event.data.from === 'gamemain') {
            console.log('我是react,我接受到了来自iframe的模型ID：', event.data);
            this.evaluateEnd();
        }
    }

    // 发送消息
    sendMsg = () => {
        const iframeDom = this.iframeRef.current;
        iframeDom.contentWindow.postMessage(1233, '*'); //window.postMessage
    }

    // 停止测评
    stopEvaluate = () => {
        this.setState({
            stopModalVisible: true,
            stopModalText: '正在测评中，您确认要停止测评吗？如停止测评将不会生成测评报告！未完成的测评项目需要重新进行测评！',
        });
    }

    // 停止ok
    stopOk = () => {
        this.setState({
            stopModalVisible: false,
        });

        this.props.reduce(2);
        this.props.history.replace(baseConfig.routeUrl + `/assessormanage`);
    }

    // 停止close
    stopCancel = () => {
        this.setState({
            stopModalVisible: false,
        });
    }

    // 测评结束
    evaluateEnd = () => {
        this.setState({ endModalVisible: true });
    }

    // 关闭弹框-测评结束
    evaluateClose = () => {
        this.setState({ endModalVisible: false });
    }

    // 查看报告
    jumpReports = () => {
        this.props.reduce(2);
        this.props.replace([{ path: '/assessmanage', name: '测评管理', grade: 1 }, { path: '/reportmanage', name: '报告管理', grade: 2 }]);
        this.props.history.replace(baseConfig.routeUrl + `/reportmanage`);
    }

    // 返回列表
    jumpAssessor = () => {
        this.props.reduce(2);
        this.props.replace([{ path: '/assessmanage', name: '测评管理', grade: 1 }, { path: '/assessormanage', name: '受测者管理', grade: 2 }]);
        this.props.history.replace(baseConfig.routeUrl + `/assessormanage`);
    }

    render() {
        const iframeSrc = this.state.iframeUrl + '?&course_id=' + this.state.course_id + '&user_id=' + this.state.user_id;

        return (
            <Card title="" className='CreateEvaluate'>

                <Prompt
                    when={false}
                    message={() => '离开当前页后，所编辑的数据将不可恢复'}
                />

                {/* 停止测评 */}
                <Modal
                    className='modals'
                    title="停止测评"
                    visible={this.state.stopModalVisible}
                    onOk={this.stopOk}
                    onCancel={this.stopCancel}
                    maskClosable={false}
                >
                    <p className='modalText'>{this.state.stopModalText}</p>
                </Modal>

                {/* 测评结束 */}
                <Modal
                    className='modals CreateEvaluate-modals-evaluate'
                    title="测评结束"
                    visible={this.state.endModalVisible}
                    onCancel={this.evaluateClose}
                    maskClosable={false}
                >
                    <p className='modalText'>本次测评已结束，您可以：</p>
                    <div style={{ textAlign: 'center', marginTop: '50px', marginBottom: '20px' }}>
                        <Button type='default' size={'large'} shape="round" onClick={this.jumpReports} >查看报告</Button>
                        <Button type='default' size={'large'} shape="round" onClick={this.jumpAssessor} style={{ marginLeft: '50px' }}>返回列表</Button>
                    </div>
                </Modal>

                <div className='header'>
                    <p className='header-title'>认知测评</p>
                    <Button className='header-stop' type='default' onClick={this.stopEvaluate} >停止测评</Button>
                </div>

                <iframe ref={this.iframeRef} style={{ width: '1100px', height: '710px', marginLeft: 'calc( 50% - 550px)' }} src={iframeSrc} title='PenguinTestmain'></iframe>
            </Card>
        )
    }
}

// 容器组件
const mapStateToProps = (state) => {
    return { breadcrumberData: state.breadcrumber, loginData: state.login }
}

const mapDispatchToProps = (dispatch) => {
    return {
        replace: (data) => { dispatch(replaceBreadcrumber(data)) },
        reduce: (data) => { dispatch(reduceBreadcrumber(data)) }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateEvaluate)