export const DomainData = {
    '192.168.123.216': '216脑智综合评估系统',
    'www.mind360.com.cn': '脑云梯脑智综合评估系统',
    'njx.zhenguigu.com': '珍圭谷科技《脑全测》',
    'hanlei.mind360.com.cn': '心理健康检测及康复系统',
    'assess.mind360.com.cn': '学生能力智能评估系统',
    'jy.mind360.com.cn': 'AI脑电心理预警云看板',
    'cp.pkuzk.cn': '学能综合评估系统-中道博雅智库',
};

export const CompanyData = {
    '192.168.123.216': '北京视友科技有限责任公司',
    'www.mind360.com.cn': '北京视友科技有限责任公司',
    'njx.zhenguigu.com': '北京视友科技有限责任公司',
    'hanlei.mind360.com.cn': '山东省社会治理中心心理健康研究所',
    'assess.mind360.com.cn': '北京视友科技有限责任公司',
    'jy.mind360.com.cn': '北京视友科技有限责任公司',
    'cp.pkuzk.cn': '中道博雅智库（北京）咨询有限公司',
}

export const CompanyDataList = [
    {
        host: '192.168.123.216',
        companyName: '北京视友科技有限责任公司',
        address: '北京大兴区北京经济技术开发区科创十二街8号北斗导航产业园2号楼a座415室',
        mail: 'develop@cusoft.net',
        productName: '216脑智综合评估系统'
    },
    {
        host: 'www.mind360.com.cn',
        companyName: '北京视友科技有限责任公司',
        address: '北京大兴区北京经济技术开发区科创十二街8号北斗导航产业园2号楼a座415室',
        mail: 'develop@cusoft.net',
        productName: '脑云梯脑智综合评估系统'
    },
    {
        host: 'njx.zhenguigu.com',
        companyName: '珍圭谷科技',
        address: '廊坊市广阳区凯悦花园18号楼底商珍圭谷科技',
        mail: 'info@zhenguigu.com',
        productName: '珍圭谷科技《脑全测》'
    },
    {
        host: 'cp.pkuzk.cn',
        companyName: '中道博雅智库（北京）咨询有限公司',
        address: '江西省赣州市章贡区油山路九铭广场8栋811',
        mail: 'postmaster@pkuzk.cn',
        productName: '学能综合评估系统-中道博雅智库'
    },
    {
        host: 'hanlei.mind360.com.cn',
        companyName: '山东省社会治理中心心理健康研究所',
        address: '山东省济南市二环东路7366号',
        mail: 'develop@cusoft.net',
        productName: '心理健康检测及康复系统'
    },
    {
        host: 'assess.mind360.com.cn',
        companyName: '北京视友科技有限责任公司',
        address: '北京大兴区北京经济技术开发区科创十二街8号北斗导航产业园2号楼a座415室',
        mail: 'develop@cusoft.net',
        productName: '学生能力智能评估系统'
    },
    {
        host: 'jy.mind360.com.cn',
        companyName: '北京视友科技有限责任公司',
        address: '北京大兴区北京经济技术开发区科创十二街8号北斗导航产业园2号楼a座415室',
        mail: 'develop@cusoft.net',
        productName: 'AI脑电心理预警云看板'
    },
]

export const UrlMathCompanyData = () => {
    let mathData = {}
    CompanyDataList.forEach((item) => {
        if (window.location.host == item['host']) {
            mathData = item
        }
    })
    return mathData
}

/**
 * 格式化时间：时分秒
 * @param {*} seconds 总秒数
 * @returns 00:00:00
 */
export const formatHourMinuteSecond = (seconds) => {
    seconds = Number(seconds) ? Number(seconds) : 0
    let hourNum = Math.floor(seconds / 60 / 60 % 24)
    let minuteNum = Math.floor(seconds / 60 % 60)
    let secondNum = Math.floor(seconds % 60)
    let hourStr = hourNum < 10 ? "0" + hourNum : hourNum;
    let minuteStr = minuteNum < 10 ? "0" + minuteNum : minuteNum;
    let secondStr = secondNum < 10 ? "0" + secondNum : secondNum;
    return `${hourStr}:${minuteStr}:${secondStr}`
}