/* 入口js */
import React from 'react';
import ReactDom from 'react-dom';
import { ConfigProvider } from 'antd';
import App from './App';
import store from './redux/store';
import { Provider } from 'react-redux';
import 'moment/locale/zh-cn';
import locale from 'antd/lib/locale/zh_CN';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor } from './redux/store';
import 'antd/dist/antd.css';

ReactDom.render(
    <ConfigProvider locale={locale}>
        <Provider store={store}>
            <PersistGate persistor={persistor}>
                <App />
            </PersistGate>
        </Provider>
    </ConfigProvider>,
    document.getElementById('root')
);
