import React from 'react';
import { Form, Row, Col, Input, Button, message } from 'antd';
import axios from 'axios';
import baseConfig from '../../config/BaseConfig';

// 表单
const ResetPwdForm = (resetPwdData) => {
    const [form] = Form.useForm();

    // 用户信息
    const userInfo = {
        fname: '0',
        num: '0',
        uname: '',
        phone: '',
        role: '',
    };

    // 
    const initUserInfo = () => {
        let temp = resetPwdData.userInfo()
        Object.assign(userInfo, temp);
        console.log(userInfo);
        form.setFieldsValue({
            fname: userInfo.fname,
            num: userInfo.num,
        });
    };

    // 表单提交
    const onFinish = (values) => {
        console.log(values)
        axios.post(baseConfig.httpUrl + '/api/user/reset-pass', { id: userInfo.id, new_pass: values.pass }).then(
            response => {
                if (response.data.res === 1) {
                    message.success('重置密码成功')
                    resetPwdData.closeModal();
                    form.setFieldsValue({
                        fname: '',
                        num: '',
                        pass:'',
                    });
                }
                else {
                    message.error(response.data.msg);
                }
            }
        );
    };

    return (
        <Form
            form={form}
            name="reset_pwd"
            className="ant-advanced-search-form "
            onFinish={onFinish}
            initialValues={{
                fname: userInfo.fname,
                num: userInfo.num,
            }}
            style={{ padding: '0 50px' }}
        >
            {initUserInfo()}
            <Row gutter={[24, 24]}>
                <Col span={24} >
                    <Form.Item
                        name='fname'
                        label='姓&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;名'
                    >
                        <Input disabled />
                    </Form.Item>
                </Col>
                <Col span={24} >
                    <Form.Item
                        name='num'
                        label='编&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;号'
                    >
                        <Input disabled />
                    </Form.Item>
                </Col>
                <Col span={24} >
                    <Form.Item
                        name='pass'
                        label='新&nbsp;&nbsp;密&nbsp;&nbsp;码'
                        rules={[
                            {
                                required: true,
                                message: '请输入新密码',
                            },
                            {
                                min: 6,
                                message: '新密码最少6位',
                            },
                            {
                                max: 32,
                                message: '新密码最多32位',
                            },
                        ]}
                    >
                        <Input.Password placeholder="请输入新密码" />
                    </Form.Item>
                </Col>
            </Row>
            <Row style={{ marginTop: '10px' }}>
                <Col span={24} style={{ textAlign: 'center' }}>
                    <Button type="primary" htmlType="submit"> 重置密码 </Button>
                </Col>
            </Row>
        </Form>
    );
};

export default ResetPwdForm;