import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Card, Button, Checkbox, message } from 'antd'
import { DomainData } from '../../config/Common'
import baseConfig from '../../config/BaseConfig'
import axios from 'axios';

import './index.less'

/**
 * 协议
 */
class Agreement extends Component {

  state = {
    agreementFlag: false
  }

  componentDidMount() { }

  /**
   * 选中
   * @param {*} e 
   */
  onChange = (e) => {
    this.setState({ agreementFlag: e.target.checked })
  }


  /**
   * 隐私政策
   */
  privacyFun = () => {
    window.open(baseConfig.routeUrl + '/privacy', '_blank')
  }

  /**
   * 用户协议
   */
  serverFun = () => {
    window.open(baseConfig.routeUrl + '/service', '_blank')
  }

  /**
     * 检查是否需要激活
     * @param {*} data 
     */
  checkActive = () => {
    axios.get(baseConfig.httpUrl + '/api/user/is-activated', {}).then(
      response => {
        if (response.data.res === 1) {
          if (response.data.is_activated) {
            this.props.history.replace(baseConfig.routeUrl);
          }
          else {
            this.props.history.replace(baseConfig.routeUrl + '/active');
          }
        }
        else {
          message.error(response.data.msg);
        }
      }
    )
  }

  /**
   * 同意
   */
  agreementFun = () => {
    if (this.state.agreementFlag) {
      axios.post(baseConfig.httpUrl + '/api/user-agreement/agree', {}).then(
        response => {
          if (response.data.res === 1) {
            if (this.props.loginData.role == 2) {
              this.checkActive()
            }
            else {
              this.props.history.replace(baseConfig.routeUrl);
            }
          }
          else {
            message.error(response.data.msg);
          }
        }
      )
    }
    else {
      message.warning('请同意《隐私政策》和《用户协议》')
    }
  }

  render() {
    let loginName = '脑云梯脑智综合评估系统';
    let domainUrl = window.location.host;
    for (let key in DomainData) {
      if (domainUrl == key) {
        loginName = DomainData[key];
      }
    }

    return (
      <div className='agreement'>
        <div className='agreement_header'>{loginName}</div>
        <div className='agreement_content'>
          <Card title={<div style={{ textAlign: "center" }}>{loginName}用户服务协议和隐私政策</div>} className='agreement_content_card'>
            <div className='agreement_content_contianer'>
              <p>尊敬的用户</p>
              <p className='agreement_operate_p'>在您开始使用我们的网站之前，我们希望您能够了解并同意我们的<span class="agreement_operate_file" onClick={this.serverFun}>《用户协议》</span>和<span class="agreement_operate_file" onClick={this.privacyFun}>《隐私政策》</span>。这些文件详细阐述了您的权利和义务，以及我们如何保护您的个人信息。</p>
              <p className='agreement_operate_p'>用户协议规定了您使用网站服务的条件，包括账号注册、服务使用、行为规范和知识产权等。我们要求您遵守这些条款，以确保一个安全和尊重的在线环境。</p>
              <p className='agreement_operate_p'>隐私政策说明了我们如何收集、使用、保护和分享您的个人信息。我们承诺采取必要的措施来保护您的隐私，并仅在提供服务或法律要求的情况下使用您的数据。</p>
              <p className='agreement_operate_p'>为了正常使用网站上的训练项目和其他功能，您必须同意这些协议和政策。在使用过程中，您将被要求勾选同意这些条款。如果您不同意，我们将无法为您提供服务。</p>
              <p className='agreement_operate_p'>感谢您的理解和合作。</p>

              <div className="agreement_operate_filetext">
                <Checkbox onChange={this.onChange}></Checkbox>
                <span> 同意</span>
                <span class="agreement_operate_file" onClick={this.serverFun}>《用户协议》</span>
                <span>和</span>
                <span class="agreement_operate_file" onClick={this.privacyFun}>《隐私政策》</span>
              </div>
              <div className='agreement_operate'>
                <Button type="primary" htmlType="submit" className="login-form-button" onClick={this.agreementFun}> 确 定 </Button>
              </div>
            </div>
          </Card>
        </div>
      </div >
    )
  }
}

// 容器组件
const mapStateToProps = (state) => {
  return { loginData: state.login }
}

const mapDispatchToProps = (dispatch) => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(Agreement);