import React, { Component } from 'react';
import { Form, Input, Button, message, Modal, Card, Radio, DatePicker, Checkbox } from 'antd';
import { connect } from 'react-redux';
import axios from 'axios';
import moment from 'moment'
import './index.less';
import baseConfig from '../../config/BaseConfig';

/* 登录 */
// UI组件
class Active extends Component {

  state = {
    agreeType: true,
  }

  constructor(props) {
    super(props);
    this.initialObj = { 'birthday': moment(this.props.loginData.birthday), 'gender': String(this.props.loginData.gender), 'fname': this.props.loginData.full_name }
  }

  componentDidMount() {
    this.checkActive()
  }

  /**
     * 检查是否需要激活
     * @param {*} data 
     */
  checkActive = () => {
    if (this.props.loginData.role === 2) {

      axios.get(baseConfig.httpUrl + '/api/user/is-activated', {}).then(
        response => {
          if (response.data.res === 1) {
            if (response.data.is_activated) {
              this.props.history.replace(baseConfig.routeUrl)
            }
          }
          else {
            message.error(response.data.msg)
            if (response.data.res === -1) {
              this.props.history.replace(baseConfig.routeUrl + '/login')
            }
          }
        }
      )
    }
    else {
      this.props.history.replace(baseConfig.routeUrl)
    }
  }

  /**
   * 表单提交
   * @param {*} data 
   */
  onFinish = (data) => {
    data.birthday = moment(data.birthday).format('YYYY-MM-DD')

    if (this.state.agreeType) {
      axios.post(baseConfig.httpUrl + '/api/user/activate', data).then(
        response => {
          if (response.data.res === 1) {
            this.props.history.replace(baseConfig.routeUrl);
          }
          else {
            message.error(response.data.msg);
          }
        }
      )
    }
    else {
      message.warning('请阅读并同意用户协议和隐私政策')
    }
  }

  /**
   * 点击 同意
   * @param {*} e 
   */
  onChangeCheckbox = (e) => {
    this.setState({ agreeType: e.target.checked })
  }

  /**
   * 用户协议
   */
  agreementFun = () => {
    window.open(baseConfig.routeUrl + '/service', '_blank')
  }

  /**
   * 隐私政策
   */
  privacyFun = () => {
    window.open(baseConfig.routeUrl + '/privacy', '_blank')
  }

  render() {
    console.log(this.initialObj)
    return (
      <div className='active'>
        <Card className='active_plane'>
          <div className='active_content'>
            <div className='active_title'>基本信息</div>

            <Form
              className="active_form"
              initialValues={this.initialObj}
              onFinish={this.onFinish}
            >
              <Form.Item
                name="fname"
                label="姓　　名"
                rules={[
                  { required: true, message: '请输入姓名', }
                ]}
              >
                <Input placeholder="请输入姓名" />
              </Form.Item>
              <Form.Item
                name="gender"
                label="性　　别"
                rules={[
                  { required: true, message: '请选择性别', },
                ]}
              >
                <Radio.Group>
                  <Radio value="1"> 男 </Radio>
                  <Radio value="2"> 女 </Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item
                name="birthday"
                label="出生日期"
                rules={[
                  {
                    required: true,
                    message: '请选择出生日期',
                  },
                ]}
              >
                <DatePicker style={{ width: '100%' }} />
              </Form.Item>

              <div className='agreeBox' style={{ display: 'none' }}>
                <Checkbox checked={this.state.agreeType} onChange={this.onChangeCheckbox}></Checkbox>
                <div>　我已阅读并同意 <a onClick={this.agreementFun}>用户协议</a> 和 <a onClick={this.privacyFun}>隐私协议</a></div>
              </div>

              <Form.Item className='submit'>
                <Button type="primary" htmlType="submit">
                  登  录
                </Button>
              </Form.Item>
            </Form>
          </div>
        </Card>
      </div >
    )
  }
}

// 容器组件
const mapStateToProps = (state) => {
  return { loginData: state.login }
}

const mapDispatchToProps = (dispatch) => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(Active);