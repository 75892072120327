
import { useEffect, useState } from "react"
import { Form, Upload, message, Button, Modal } from "antd"
import { UploadOutlined } from '@ant-design/icons'
import './index.less'

/**
 * 学生管理-批量导入
 * @param {*} props 
 * @returns 
 */
const AssessorAddImport = (props) => {

    const formItemLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 12 },
    }
    const [formExample] = Form.useForm()
    // 表单初始值
    const formInitialValues = {}
    // 上传文件
    const [uploadFiles, setUploadFiles] = useState([])
    // 上传文件显示
    const [uploadFileBtnDisplay, setUploadFileBtnDisplay] = useState('inline-block')

    // 上传文件
    const uploadProps = {
        beforeUpload: file => {
            // if (file.type !== 'text/csv' || file.type !== 'application/vnd.ms-excel') {
            if (file.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
                message.error(`请选择xlsx文件`)
            }
            else {
                setUploadFiles([file])
                setUploadFileBtnDisplay('none')
            }
            return false
        },
        onRemove: () => {
            setUploadFiles(() => {
                if (uploadFiles.length >= 1) {
                    setTimeout(() => {
                        setUploadFileBtnDisplay('inline-block')
                    }, 300)
                    return []
                }
            })
        },
        fileList: uploadFiles
    };

    /**
     * 表单提交
     */
    const finishFun = (formData) => {
        let tempFormData = new FormData();
        tempFormData.append('file', formData.uploadFile.file)
        props.confirmFun(tempFormData)
    }

    /**
     * 检查上传文件
     * @param {*} rule 
     * @param {*} value 
     * @param {*} callback 
     * @returns 
     */
    const checkUpload = (rule, value, callback) => {
        if (value && value !== '') {
            if (value.fileList.length !== 0 && (value.fileList[0].type === 'text/csv' || value.fileList[0].type === 'application/vnd.ms-excel' || value.fileList[0].type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')) {
                return Promise.resolve()
            }
            else {
                return Promise.reject(new Error("请选择上传文件"))
            }
        }
        else {
            return Promise.reject(new Error("请选择上传文件"))
        }
    }

    return (
        <Modal
            className='modals modals-noButton assessormanage-addStuImport'
            title='批量导入'
            visible={props.visible}
            onCancel={props.cancelFun}
            maskClosable={false}
        >
            <Form
                {...formItemLayout}
                className="assessormanageaddimport"
                autoComplete="off"
                form={formExample}
                initialValues={formInitialValues}
                onFinish={finishFun}
            >
                <Form.Item
                    label="上传文件："
                    name="uploadFile"
                    className="upload_file_item"
                    rules={[{ required: true, validator: checkUpload }]}
                >
                    <Upload {...uploadProps}>
                        <Button className="selectFile" style={{ display: uploadFileBtnDisplay, width:"100px" }} icon={<UploadOutlined />}>选择文件</Button>
                    </Upload>
                </Form.Item>
                <div className='modals-noButton-operator'>
                    <Button type="default" onClick={props.cancelFun} htmlType="button">取消</Button>
                    <Button type='primary' className='modals-noButton-operator-confirmButton' htmlType='submit'>确定</Button>
                </div>
            </Form>
        </Modal>
    )
}

export default AssessorAddImport