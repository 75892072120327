/* 
	定义action对象中type类型的常量值
*/
export const LOGIN = 'login';
export const REPLACE_BREADCRUMBER = 'replace_breadcrumber';
export const ADD_BREADCRUMBER = 'add_breadcrumber';
export const REDUCE_BREADCRUMBER = 'reduce_breadcrumber';
export const CHANGEROLE_LEFTNAV = 'changeRole_leftNav';


/* 
	定义page组件中的常量值
*/
export const FORM_INPUT = 'form_input';
export const FORM_SELECT = 'form_select';
export const FORM_DATEPICKER = 'form_datepicker';
export const FORM_TAG = 'form_tag';
export const FORM_PASS = 'form_PASS';

// 单个
export const SINGLE = 'single';
// 批量
export const BATCH = 'batch';
// 新增
export const ADD_USER = 'add_user';
// 编辑
export const EDIT_USER = 'edit_user';
// 角色-管理员
export const ROLE_ADMINISTER = 8;
// 角色-操作员
export const ROLE_OPERATOR = 4;
// 角色-助理
export const ROLE_ASSISTANT = 16;
// 角色 学生
export const ROLE_STUDENT = 2;