const AssessData = [
    // {
    //     title: '脑发育功能测评',
    //     key: '1',
    //     children: [ // 子菜单列表
    //         {
    //             title: '脑发育基础测评',
    //             key: '10060002',
    //             icon: 'none',
    //         },
    //         {
    //             title: '脑发育综合测评',
    //             key: '321',
    //             icon: 'none',
    //         },
    //         {
    //             title: 'SSVEP专项测评',
    //             key: '213',
    //             icon: 'none',
    //         },
    //     ]
    // },
    // {
    //     title: '脑认知功能测评',
    //     key: '2',
    //     children: [
    //         {
    //             title: '认知能力基础测评',
    //             key: '100302003',
    //             icon: 'none',
    //         },
    //         {
    //             title: '记忆能力专项测评',
    //             key: '432',
    //             icon: 'none',
    //         },
    //         {
    //             title: '执行功能专项测评',
    //             key: '342',
    //             icon: 'none',
    //         },
    //     ]
    // },
    {
        "title": "脑发育基础测评",
        "key": "10",
        "children": [
            {
                "title": "脑发育基础测评",
                "key": "8888888"
            }
        ]
    },
    {
        "title": "脑认知功能测评",
        "key": "20",
        "children": [
            {
                "title": "认知能力综合测评",
                "key": "10000001"
            },
            {
                "title": "注意力专项测评",
                "key": "10020002"
            },
            {
                "title": "记忆力专项测评",
                "key": "10040002"
            },
            {
                "title": "思维力专项测评",
                "key": "10060002"
            },
            {
                "title": "反应力专项测评",
                "key": "10010002"
            },
            {
                "title": "空间能力专项测评",
                "key": "10030002"
            },
            {
                "title": "情绪能力专项测评",
                "key": "10050001"
            }
        ]
    }
]

export default AssessData;