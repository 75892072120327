import React, { Component } from 'react';
import { Card, Row, Col, Input, Button, Modal, message } from 'antd';
import axios from 'axios';
import baseConfig from '../../config/BaseConfig';
import './SafetySetting.less'

export default class SafetySetting extends Component {

    newPhoneRef = React.createRef();
    msgCodeRef = React.createRef();
    phonePwdRef = React.createRef();
    newEmailRef = React.createRef();
    emailPwdRef = React.createRef();
    primaryPwdRef = React.createRef();
    newOnePwdRef = React.createRef();
    newTwoPwdRef = React.createRef();

    state = {
        phoneBtnText: '绑定',
        emailBtnText: '绑定',
        email: '',
        phone: '',
        phoneModalVisible: false,
        emailModalVisible: false,
        pwdModalVisible: false,
        msgBtnText: '获取验证码',
        phoneErrorText: '',
        msgBtnType: false,
        emailErrorText: '',
        pwdErrorText: '',
    }

    // 组件挂载
    componentDidMount() {
        this.safetyInfo();
    }

    // 安全信息
    safetyInfo = () => {
        axios.get(baseConfig.httpUrl + '/api/user/get-user-info').then(
            response => {
                if (response.data.res === 1) {
                    const { user } = response.data;

                    this.setState({
                        phoneBtnText: user.phone ? '修改' : '绑定',
                        emailBtnText: user.email ? '修改' : '绑定',
                        phone: user.phone ? user.phone : '未绑定手机',
                        email: user.email ? user.email : '未绑定邮箱',
                    });
                }
            }
        )
    }

    // 修改手机号
    editPhone = () => {
        this.setState({
            phoneModalVisible: true
        });
    }

    // 关闭弹框
    modalCancel = () => {
        if (this.newPhoneRef.current) {
            this.newPhoneRef.current.state.value = '';
            this.msgCodeRef.current.state.value = '';
            this.phonePwdRef.current.state.value = '';
        }

        if (this.newEmailRef.current) {
            this.newEmailRef.current.state.value = '';
            this.emailPwdRef.current.state.value = '';
        }

        if (this.primaryPwdRef.current) {
            this.primaryPwdRef.current.state.value = '';
            this.newOnePwdRef.current.state.value = '';
            this.newTwoPwdRef.current.state.value = '';
        }

        this.setState({
            phoneModalVisible: false,
            emailModalVisible: false,
            pwdModalVisible: false,
            phoneErrorText: '',
            emailErrorText: '',
            pwdErrorText: '',
        });
    }

    // 发送短信验证码
    sendMsg = () => {
        if (this.verifyPhone()) {
            this.setState({ msgBtnType: true });
            axios.post(baseConfig.httpUrl + '/api/security/sms-code', { phone: this.newPhoneRef.current.state.value }).then(
                response => {
                    if (response.data.res === 1) {
                        this.countDown();
                    }
                    else {
                        message.error(response.data.msg);
                        this.setState({ msgBtnType: false });
                    }
                }
            );
        }
    }

    // 倒计时
    countDown = () => {
        let secondNum = 60;
        let countDownId = setInterval(() => {
            secondNum -= 1;

            this.setState({ msgBtnText: '重新获取(' + secondNum + ')' });
            if (secondNum === 0) {
                clearInterval(countDownId);
                this.setState({ msgBtnType: false, msgBtnText: '获取验证码' });
            }
        }, 1000);
    }

    // 校验手机
    verifyPhone = () => {
        const newPhone = this.newPhoneRef.current.state.value;
        const regExp = new RegExp(/^1\d{10}$/, "g");
        if (!newPhone) {
            this.setState({ phoneErrorText: '请输入手机号码' });
            return false;
        }
        else if (!regExp.test(newPhone)) {
            this.setState({ phoneErrorText: '输入的手机号码格式不正确' });
            return false;
        }
        else {
            this.setState({ phoneErrorText: '' });
            return true;
        }
    }

    // 绑定手机
    bindPhone = () => {
        const newPhone = this.newPhoneRef.current.state.value;
        const msgCode = this.msgCodeRef.current.state.value;
        const pwd = this.phonePwdRef.current.state.value;

        if (!this.verifyPhone()) {

        }
        else if (!msgCode) {
            this.setState({ phoneErrorText: '请输入短信验证码' });
        }
        else if (!pwd) {
            this.setState({ phoneErrorText: '请输入账号密码' });
        }
        else {
            axios.post(baseConfig.httpUrl + '/api/security/bind-phone', { phone: newPhone, code: msgCode, pass: pwd }).then(
                response => {
                    if (response.data.res === 1) {
                        message.success('手机号码绑定成功');
                        this.modalCancel();
                        this.safetyInfo();
                    }
                    else {
                        this.setState({ phoneErrorText: response.data.msg });
                    }
                }
            );
        }
    }

    // 修改email
    editEmail = () => {
        this.setState({ emailModalVisible: true });
    }

    // 绑定邮箱
    bindEmail = () => {
        const newEmail = this.newEmailRef.current.state.value;
        const pwd = this.emailPwdRef.current.state.value;
        const regExp = new RegExp(/^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(.[a-zA-Z0-9_-]+)+$/, "g");

        if (!newEmail) {
            this.setState({ emailErrorText: '请输入邮箱地址' });
        }
        else if (!regExp.test(newEmail)) {
            this.setState({ emailErrorText: '请输入正确格式邮箱地址' });
        }
        else if (!pwd) {
            this.setState({ emailErrorText: '请输入账号密码' });
        }
        else {
            this.setState({ emailErrorText: '' });
            axios.post(baseConfig.httpUrl + '/api/security/bind-email', { email: newEmail, pass: pwd }).then(
                response => {
                    if (response.data.res === 1) {
                        this.setState({ emailErrorText: '验证邮件发送成功，请按照邮件内容进行操作以绑定邮箱。' });
                    }
                    else {
                        this.setState({ emailErrorText: response.data.msg });
                    }
                }
            );
        }
    }

    // 修改密码
    editPwd = () => {
        this.setState({ pwdModalVisible: true });
    }

    // 
    bindPwd = () => {
        const primaryPwd = this.primaryPwdRef.current.state.value;
        const newOnePwd = this.newOnePwdRef.current.state.value;
        const newTwoPwd = this.newTwoPwdRef.current.state.value;
        const regExp = new RegExp(/^[0-9A-Za-z]{6,32}$/, "g");

        if (!primaryPwd) {
            this.setState({ pwdErrorText: '请输入原密码' });
        }
        else if (!newOnePwd) {
            this.setState({ pwdErrorText: '请输入新密码' });
        }
        else if (!newTwoPwd) {
            this.setState({ pwdErrorText: '请再次输入新密码' });
        }
        else if (newOnePwd !== newTwoPwd) {
            this.setState({ pwdErrorText: '两次输入的密码不一致' });
        }
        else if (!regExp.test(newOnePwd)) {
            this.setState({ pwdErrorText: '输入密码不符合规则：密码由数字和英文组成，长度6-32位' });
        }
        else {
            this.setState({ pwdErrorText: '' });
            axios.post(baseConfig.httpUrl + '/api/security/update-pass', { old_pass: primaryPwd, new_pass: newOnePwd }).then(
                response => {
                    if (response.data.res === 1) {
                        message.success('密码修改成功');
                        this.modalCancel();
                    }
                    else {
                        this.setState({ pwdErrorText: response.data.msg });
                    }
                }
            );
        }
    }

    render() {
        return (
            <Card title="安全设置" className='SafetySetting' style={{ minHeight: '100%' }}>
                <Row style={{ display: 'none' }}>
                    <Col style={{ textAlign: 'right', lineHeight: 2 }} span={2}>手&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;机：</Col>
                    <Col span={6} style={{ paddingLeft: 20 }}> <Input value={this.state.phone} disabled={true} /> </Col>
                    <Col span={4}> <Button type="primary" htmlType="button" style={{ marginLeft: 15 }} onClick={this.editPhone}>{this.state.phoneBtnText}</Button> </Col>
                </Row>
                <Row style={{ marginTop: 25 }}>
                    <Col style={{ textAlign: 'right', lineHeight: 2 }} span={2}>邮&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;箱：</Col>
                    <Col span={6} style={{ paddingLeft: 20 }}> <Input value={this.state.email} disabled={true} /> </Col>
                    <Col span={4}> <Button type="primary" htmlType="button" style={{ marginLeft: 15 }} onClick={this.editEmail}>{this.state.emailBtnText}</Button> </Col>
                </Row>
                <Row style={{ marginTop: 25 }}>
                    <Col style={{ textAlign: 'right', lineHeight: 2 }} span={2}>修改密码：</Col>
                    <Col span={6} style={{ paddingLeft: 20 }}> <Input disabled={true} value='************' /> </Col>
                    <Col span={4}> <Button type="primary" htmlType="button" style={{ marginLeft: 15 }} onClick={this.editPwd} >修改</Button> </Col>
                </Row>

                {/* 绑定手机号 */}
                <Modal
                    className='modals SafetySetting-phone'
                    title="绑定手机号"
                    visible={this.state.phoneModalVisible}
                    onCancel={this.modalCancel}
                    maskClosable={false}
                >
                    <Row>
                        <Col span={10} offset={4}>
                            <Input ref={this.newPhoneRef} placeholder='请输入手机号' />
                        </Col>
                    </Row>
                    <Row style={{ marginTop: 20 }}>
                        <Col span={10} offset={4}>
                            <Input ref={this.msgCodeRef} placeholder='请输入短信验证码' />
                        </Col>
                        <Col span={4}>
                            <Button htmlType="button" style={{ marginLeft: 15 }} onClick={this.sendMsg} disabled={this.state.msgBtnType} >
                                {this.state.msgBtnText}
                            </Button>
                        </Col>

                    </Row>
                    <Row style={{ marginTop: 20 }}>
                        <Col span={10} offset={4}>
                            <Input.Password ref={this.phonePwdRef} placeholder='请输入密码' />
                        </Col>
                        <Col span={4}>
                            <Button htmlType="button" style={{ marginLeft: 15 }} onClick={this.bindPhone}>绑定手机号</Button>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: 20 }}>
                        <p style={{ textAlign: 'center', margin: 0, color: '#ff0000', width: '100%', lineHeight: '32px' }}>{this.state.phoneErrorText}</p>
                    </Row>
                </Modal>

                {/* 绑定邮箱 */}
                <Modal
                    className='modals SafetySetting-email'
                    title="绑定邮箱"
                    visible={this.state.emailModalVisible}
                    onCancel={this.modalCancel}
                    maskClosable={false}
                >
                    <Row>
                        <Col span={10} offset={4}>
                            <Input ref={this.newEmailRef} placeholder='请输入邮箱地址' />
                        </Col>
                    </Row>
                    <Row style={{ marginTop: 20 }}>
                        <Col span={10} offset={4}>
                            <Input.Password ref={this.emailPwdRef} placeholder='请输入密码' />
                        </Col>
                        <Col span={4}>
                            <Button htmlType="button" style={{ marginLeft: 15 }} onClick={this.bindEmail}>发送绑定邮件</Button>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: 20 }}>
                        <p style={{ textAlign: 'center', margin: 0, color: '#ff0000', width: '100%', lineHeight: '32px' }}>{this.state.emailErrorText}</p>
                    </Row>
                </Modal>

                {/* 修改密码 */}
                <Modal
                    className='modals SafetySetting-pwd'
                    title="修改密码"
                    visible={this.state.pwdModalVisible}
                    onCancel={this.modalCancel}
                    maskClosable={false}
                >
                    <Row>
                        <Col span={12} offset={6}>
                            <Input.Password ref={this.primaryPwdRef} placeholder='请输入原密码' />
                        </Col>
                    </Row>
                    <Row style={{ marginTop: 20 }}>
                        <Col span={12} offset={6}>
                            <Input.Password ref={this.newOnePwdRef} placeholder='请输入新密码' />
                        </Col>
                    </Row>
                    <Row style={{ marginTop: 20 }}>
                        <Col span={12} offset={6}>
                            <Input.Password ref={this.newTwoPwdRef} placeholder='请再次输入新密码' />
                        </Col>
                    </Row>
                    <Row style={{ marginTop: 20 }}>
                        <Col span={24} style={{ textAlign: 'center' }}>
                            <Button htmlType="button" onClick={this.bindPwd}>确定修改</Button>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: 15 }}>
                        <p style={{ textAlign: 'center', margin: 0, color: '#ff0000', width: '100%', lineHeight: '32px' }}>{this.state.pwdErrorText}</p>
                    </Row>
                </Modal>
            </Card >
        )
    }
}
