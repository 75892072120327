import React, { Component } from 'react';
import { Layout, Menu, message } from 'antd';
import { connect } from 'react-redux'
import { UserOutlined, AimOutlined } from '@ant-design/icons';
import axios from 'axios';
import baseConfig from '../../config/BaseConfig';
import { replaceBreadcrumber } from '../../redux/actions/Breadcrumber'
import './HeaderNav.less';
import { Link } from 'react-router-dom';
import { ROLE_STUDENT } from '../../config/constant';

const { Header } = Layout;
const { SubMenu } = Menu;

/* 顶部导航 组件 */
class HeaderNav extends Component {

    // 退出登录
    logout = () => {
        axios.post(baseConfig.httpUrl + `/api/user/logout`).then(
            response => {
                if (response.data.res === 1) {
                    this.props.adminLogout();
                }
                else {
                    message.error(response.data.msg);
                }
            }
        )
    }

    // 
    clickItem = (path) => {
        return () => {
            let tempObj = [];
            if (path === '/assessormanage') {
                tempObj = [...[{ path: '/assessmanage', name: '测评管理', grade: 1 }, { path: path, name: '受测者管理', grade: 2 }]];
            }
            else if (path === '/personaldata') {
                tempObj = [...[{ path: '/usercenter', name: '用户中心', grade: 1 }, { path: path, name: '个人资料', grade: 2 }]];
            }
            else if (path === '/safetysetting') {
                tempObj = [...[{ path: '/usercenter', name: '用户中心', grade: 1 }, { path: path, name: '安全设置', grade: 2 }]];
            }
            else if (path === '/accountrecharge') {
                tempObj = [...[{ path: '/usercenter', name: '用户中心', grade: 1 }, { path: path, name: '账户充值', grade: 2 }]];
            }
            this.props.replace(tempObj);
        }
    }

    /**
   * 用户协议
   */
    agreementFun = () => {
        window.open(baseConfig.routeUrl + '/service', '_blank')
    }

    /**
     * 隐私政策
     */
    privacyFun = () => {
        window.open(baseConfig.routeUrl + '/privacy', '_blank')
    }

    render() {
        let isStuType = this.props.loginData.role === ROLE_STUDENT ? true : false;
        let login_name = this.props.loginData.full_name ? this.props.loginData.full_name : '未知登录人';
        let productName = this.props.loginData.group_name ? this.props.loginData.group_name : '脑云梯脑智综合评估系统';

        return (
            <Header className="header">
                <div className="header_logo" />
                <span className='header-title'>{productName}</span>
                <Menu theme="light" mode="horizontal" className='header-menu'>
                    {
                        false && !isStuType && <Menu.Item className='header-menu-item' key="assess" icon={<AimOutlined />} onClick={this.clickItem("/assessormanage")}>
                            <Link to={baseConfig.routeUrl + '/assessormanage'}>快速测评</Link>
                        </Menu.Item>
                    }
                    <Menu.SubMenu key="admin" className='header-menu-item' icon={<UserOutlined />} title={login_name}>
                        {!isStuType && <Menu.Item className='header-list-item' key="baseInfo" onClick={this.clickItem("/personaldata")}><Link to={baseConfig.routeUrl + '/personaldata'}>基本资料</Link></Menu.Item>}
                        {!isStuType && <Menu.Item className='header-list-item' key="editPwd" onClick={this.clickItem("/safetysetting")}><Link to={baseConfig.routeUrl + '/safetysetting'}>更改密码</Link></Menu.Item>}
                        {!isStuType && <Menu.Item className='header-list-item' key="recharge" onClick={this.clickItem("/accountrecharge")}><Link to={baseConfig.routeUrl + '/accountrecharge'}>账户充值</Link></Menu.Item>}
                        <Menu.Item className='header-list-item' key="agreement" onClick={this.agreementFun}>用户协议</Menu.Item>
                        <Menu.Item className='header-list-item' key="privacy" onClick={this.privacyFun}>隐私政策</Menu.Item>
                        <Menu.Item className='header-list-item' key="logout" onClick={this.logout}>退出登录</Menu.Item>
                    </Menu.SubMenu>
                </Menu>
            </Header>
        )
    }
}

// 容器组件
const mapStateToProps = (state) => {
    return { loginData: state.login }
}

const mapDispatchToProps = (dispatch) => {
    return {
        replace: (data) => { dispatch(replaceBreadcrumber(data)) }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HeaderNav)