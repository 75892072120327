import React, { Component } from 'react';
import { Form, Input, Button, Select, Row, Col, DatePicker, Tag, Modal, Table, message, Tooltip } from 'antd';
import { ADD_USER, EDIT_USER, FORM_DATEPICKER, FORM_INPUT, FORM_PASS, FORM_SELECT, FORM_TAG } from '../../config/constant';
import axios from 'axios';
import baseConfig from '../../config/BaseConfig';
import { PlusOutlined } from '@ant-design/icons';
import './DateilFrom.less';

const { Option } = Select;

const layout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 16,
    },
};

const dateFormat = 'YYYY-MM-DD';

export default class DateilFrom extends Component {
    formRef = React.createRef();

    constructor(props) {
        super(props);

        this.state = {
            editType: true,
            addBtn: 'none',
            editBtn: 'none',
            saveBtn: 'none',

            labelsModalVisible: false,
            labelsList: [],
            curLabelsList: [],
            selectedRowKeys: [],
            selectedRowRecords: [],
            labelsDisplay: 'none',
            passInputReadOnly: true
        }

        if ('labels' in props) {
            this.state.labelsDisplay = 'block';
            this.state.curLabelsList = props.labels ? [...props.labels] : [];
        }
        else {
            this.state.labelsDisplay = 'none';
        }

        // 新增
        if (props.formType === ADD_USER) {
            this.state.editType = false;
            this.state.addBtn = 'inline-block';
            this.state.editBtn = 'none';
            this.state.saveBtn = 'none';
        }
        // 编辑
        else if (props.formType === EDIT_USER) {
            this.state.editType = true;
            this.state.addBtn = 'none';
            this.state.editBtn = 'inline-block';
            this.state.saveBtn = 'none';
        }
        // 只读
        if (props.readOnlyType) {
            this.state.addBtn = 'none'
            this.state.editBtn = 'none'
            this.state.saveBtn = 'none'
        }
        this.props.onRef && this.props.onRef(this);
    }

    lablesColumns = [
        {
            title: '名称',
            dataIndex: 'name',
            align: 'center',
        },
    ]

    // 重置表单
    onReset = () => {
        this.formRef.current.resetFields();
    };

    onFill = () => {
        this.formRef.current.setFieldsValue({
            note: 'Hello world!',
            gender: 'male',
        });
    };

    // 创建表单
    createFroms = () => {
        const { formData } = this.props
        const children = [];
        for (let i = 0; i < formData.length; i++) {

            let templayout = {
                labelCol: {
                    span: 6 / formData[i].span * 8,
                },
                wrapperCol: {
                    span: 24 - 6 / formData[i].span * 8,
                },
            };

            children.push(
                <Col span={formData[i].span} key={i}>
                    <Form.Item
                        {...templayout}
                        name={formData[i].seacrhType}
                        label={formData[i].title}
                        rules={formData[i].rules}
                    >
                        {
                            this.createFromItem(formData[i])
                        }
                    </Form.Item>
                </Col>,
            );
        }

        return children;
    };

    // 表单items
    createFromItem = (itemData) => {
        if (itemData.inputType === FORM_INPUT) {
            let tempPlaceholder = itemData.placeholder ? itemData.placeholder : itemData.title.replace(/\s*/g, "");
            if (itemData.seacrhType === 'num') {
                return <Input disabled />
            }
            else if (itemData.seacrhType === 'uname') {
                return <Input placeholder={'请输入' + tempPlaceholder} disabled={this.state.editType} onBlur={this.checkRepeatUname} />
            }
            else {
                return <Input placeholder={'请输入' + tempPlaceholder} disabled={this.state.editType} />
            }
        }
        else if (itemData.inputType === FORM_SELECT) {

            return (
                <Select style={{ width: '100%' }} disabled={this.state.editType}>
                    {
                        this.createSelectOption(itemData.children)
                    }
                </Select>
            )
        }
        else if (itemData.inputType === FORM_DATEPICKER) {

            return (
                <DatePicker style={{ width: '100%' }} disabled={this.state.editType} format={dateFormat} />
            )
        }
        else if (itemData.inputType === FORM_PASS) {
            return (
                // <DatePicker style={{ width: '100%' }} disabled={this.state.editType} format={dateFormat} />
                <Input.Password placeholder="请输入密码" disabled={this.state.editType} autoComplete='new-password'
                    readOnly={this.state.passInputReadOnly}
                    onBlur={() => { this.setState({ passInputReadOnly: true }) }}
                    onFocus={() => { this.setState({ passInputReadOnly: false }) }}
                    onChange={this.onPassInputChange}
                />
            )
        }
    }

    // 搜索条件select中option
    createSelectOption = (selectData) => {
        const options = [];
        for (let j = 0; j < selectData.length; j++) {
            options.push(
                <Option key={selectData[j].value} value={selectData[j].value}>{selectData[j].title}</Option>
            )
        }
        return options;
    }

    // 表单提交-搜索
    onFinish = (values) => {
        if ('labels' in this.props) {
            values.labels = this.state.curLabelsList.map(item => { return item.id })
        }
        this.props.submitFun(values);
    };

    // 检查是否重名
    checkRepeatUname = (e) => {
        axios.get(baseConfig.httpUrl + '/api/user/login-name-valid', { params: { login_name: e.target.value } }).then(
            response => {
                if (response.data.res === 1) {

                }
                else {

                }
            }
        )
    }

    // 新增
    addFun = () => {
        this.formRef.current.submit();
    }

    // 编辑
    editFun = () => {
        this.setState({
            editType: false,
            editBtn: 'none',
            saveBtn: 'inline-block',
        })
    }

    // 保存
    saveFun = () => {
        this.formRef.current.submit();
    }

    /**
     * 增加 属性
     */
    addTagFun = () => {
        const { curLabelsList } = this.state;
        axios.get(baseConfig.httpUrl + '/api/label/list', { params: {} }).then(
            response => {
                if (response.data.res === 1) {
                    const { list } = response.data;

                    list.map((item) => {
                        return item.key = item.id;
                    });

                    let curId = curLabelsList.map(item => { return item.id })

                    this.setState({ labelsModalVisible: true, labelsList: list, selectedRowKeys: [...curId] });
                }
                else {
                    message.error(response.data.msg);
                }
            }
        )
    }

    /**
     * 增加属性 确定
     */
    addTagComfirm = () => {
        const { curLabelsList, selectedRowRecords } = this.state
        let tempAddArr = [];
        selectedRowRecords.forEach(item => {
            let tempType = true;

            curLabelsList.forEach(elm => {
                if (item.id == elm.id) {
                    tempType = tempType && false
                }
            })

            if (tempType) {
                tempAddArr.push(item);
            }
        })

        this.setState({ curLabelsList: [...curLabelsList, ...tempAddArr] })
        this.addTagCanel()
    }

    /**
     * 增加属性 取消
     */
    addTagCanel = () => {
        this.setState({ labelsModalVisible: false, selectedRowKeys: [], selectedRowRecords: [] });
    }

    /**
     * 删除 属性
     * @param {*} id 
     */
    reduceTag = (id) => {
        let tmepArr = this.state.curLabelsList.filter((item) => {
            return item.id !== id
        })
        this.setState({ curLabelsList: [...tmepArr] })
    }

    // 列表 每行数据选择checkbox
    onSelectChange = (newSelectedRowKeys, rowRecord) => {
        this.setState({ selectedRowKeys: newSelectedRowKeys, selectedRowRecords: rowRecord });
    };

    onPassInputChange = (e) => {
        // this.setState({ passInputReadOnly: e.target.value === '' ? true : false })
    }


    render() {
        const templayout = {
            labelCol: { span: 4 },
            wrapperCol: { span: 20 },
        }

        const { selectedRowKeys, tableLoading, curLabelsList, labelsDisplay } = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };

        let labelsTitle = '适\xa0\xa0应\xa0\xa0症'

        return (
            <div>

                <Modal
                    className='modals'
                    title="适应症"
                    visible={this.state.labelsModalVisible}
                    onOk={this.addTagComfirm}
                    onCancel={this.addTagCanel}
                    maskClosable={false}
                >
                    <Table
                        dataSource={this.state.labelsList}
                        pagination={false}
                        columns={this.lablesColumns}
                        rowSelection={rowSelection}
                        loading={tableLoading}
                        scroll={{ y: 400 }}
                    >
                    </Table>
                </Modal>

                <Form
                    {...layout}
                    ref={this.formRef}
                    name="control-ref"
                    className='dateilfrom'
                    onFinish={this.onFinish}
                    initialValues={
                        this.props.formInitialValue
                    }
                >
                    <Row gutter={[0, 24]}>
                        {this.createFroms()}

                        {/* 属性 */}
                        <Col span={12} style={{ display: labelsDisplay }}>
                            <Form.Item
                                {...templayout}
                                name='labels'
                                label={labelsTitle}
                            >
                                <div className='labels-container'>
                                    <div className='tag-container'>
                                        {
                                            curLabelsList.map((item) => {
                                                return <Tag className='tag-item' key={item.id} closable={!this.state.editType} onClose={(e) => { this.reduceTag(item.id) }} >{item.name}</Tag>
                                            })
                                        }
                                    </div>
                                    <Tooltip title="增加" >
                                        <Button shape="circle" style={{ display: this.state.editType ? 'none' : 'block' }} onClick={() => { this.addTagFun() }}><PlusOutlined /></Button>
                                    </Tooltip>
                                    {/* <Button type="primary" htmlType="button" style={{ display: this.state.editType ? 'none' : 'flex' }} onClick={this.addTagFun} >增加</Button> */}
                                </div>
                            </Form.Item>
                        </Col>

                        <Col
                            span={12}
                            style={{
                                textAlign: 'right',
                            }}
                        >

                        </Col>
                    </Row>
                    <Row>
                        <Col span={24} style={{ textAlign: 'right' }}>
                            <Button type="primary" htmlType="button" style={{ display: this.state.addBtn }} onClick={this.addFun} > 新增 </Button>
                            <Button type="primary" htmlType="button" style={{ display: this.state.editBtn }} onClick={this.editFun}> 编辑 </Button>
                            <Button type="primary" htmlType="button" style={{ display: this.state.saveBtn }} onClick={this.saveFun}> 保存 </Button>
                        </Col>
                    </Row>
                </Form>
            </div>
        );
    }
}