import React, { Component } from 'react';
import { Breadcrumb } from 'antd';
import { connect } from 'react-redux';
import { reduceBreadcrumber } from '../../redux/actions/Breadcrumber';
import MyNavLink from '../MyNavLink/MyNavLink';
import baseConfig from '../../config/BaseConfig';
import './Breadcrumber.less';

class Breadcrumber extends Component {

    // 初始化
    initBreadcrumb = () => {
        const items = this.props.breadcrumberData;
        const breadcrumbItems = [];

        for (let i = 0; i < items.length; i++) {
            if (items[i].grade === 1) {
                breadcrumbItems.push(<Breadcrumb.Item className='breadcrumb-item' key={items[i].grade}>{items[i].name}</Breadcrumb.Item>)
            }
            else {
                breadcrumbItems.push(<Breadcrumb.Item className='breadcrumb-item' key={items[i].grade} onClick={() => { this.clickBreadcrumbItem(items[i].grade) }}> <MyNavLink to={baseConfig.routeUrl + items[i].path}>{items[i].name}</MyNavLink> </Breadcrumb.Item>)
            }
        }

        return breadcrumbItems;
    }

    // 点击item
    clickBreadcrumbItem = (currentGrade) => {
        this.props.reduce(currentGrade);
    }

    render() {
        return (
            <Breadcrumb className='breadcrumb' separator=">">
                {
                    this.initBreadcrumb()
                }
            </Breadcrumb>
        )
    }
}


// 容器组件
const mapStateToProps = (state) => {
    return { breadcrumberData: state.breadcrumber }
}

const mapDispatchToProps = (dispatch) => {
    return {
        reduce: (data) => { dispatch(reduceBreadcrumber(data)) },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Breadcrumber)