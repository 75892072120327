const EducationData = [
    { "value": '1', "title": "幼儿园小班" },
    { "value": '2', "title": "幼儿园中班" },
    { "value": '3', "title": "幼儿园大班" },
    { "value": '4', "title": "幼儿园学前班" },
    { "value": '5', "title": "小学一年级" },
    { "value": '6', "title": "小学二年级" },
    { "value": '7', "title": "小学三年级" },
    { "value": '8', "title": "小学四年级" },
    { "value": '9', "title": "小学五年级" },
    { "value": '10', "title": "小学六年级" },
    { "value": '11', "title": "中学一年级" },
    { "value": '12', "title": "中学二年级" },
    { "value": '13', "title": "中学三年级" },
    { "value": '14', "title": "高中一年级" },
    { "value": '15', "title": "高中二年级" },
    { "value": '16', "title": "高中三年级" },
    { "value": '17', "title": "职高一年级" },
    { "value": '18', "title": "职高二年级" },
    { "value": '19', "title": "职高三年级" },
    { "value": '20', "title": "中专一年级" },
    { "value": '21', "title": "中专二年级" },
    { "value": '22', "title": "中专三年级" },
    { "value": '23', "title": "大专一年级" },
    { "value": '24', "title": "大专二年级" },
    { "value": '25', "title": "大专三年级" },
    { "value": '26', "title": "大专四年级" },
    { "value": '27', "title": "大专五年级" },
    { "value": '28', "title": "本科一年级" },
    { "value": '29', "title": "本科二年级" },
    { "value": '30', "title": "本科三年级" },
    { "value": '31', "title": "本科四年级" },
    { "value": '32', "title": "本科五年级" },
    { "value": '33', "title": "硕士" },
    { "value": '34', "title": "博士" },
    { "value": '35', "title": "成人" },
]


export default EducationData