import React, { Component } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux'
import { adminTransfer } from '../../redux/actions/Admin'

import { Layout } from 'antd';
import LeftNav from '../../components/LeftNav/LeftNav';
import HeaderNav from '../../components/HeaderNav/HeaderNav';
// import { UserOutlined, LaptopOutlined, NotificationOutlined } from '@ant-design/icons';

import './Admin.less'

import UserManage from '../UserManage/UserManage';
import OrganSetting from '../OrganSetting/OrganSetting';
import Breadcrumber from '../../components/Breadcrumber/Breadcrumber';
import baseConfig from '../../config/BaseConfig';
import UserDetail from '../UserDetail/UserDetail';
import CreateEvaluate from '../CreateEvaluate/CreateEvaluate';
import AssessorManage from '../AssessorManage/AssessorManage';
import ShareManage from '../ShareManage/ShareManage';
import AssessorDetail from '../AssessorDetail/AssessorDetail';
import CreateAssess from '../CreateAssess/CreateAssess';
import ReportManage from '../ReportManage/ReportManage';
import PersonalData from '../PersonalData/PersonalData';
import SafetySetting from '../SafetySetting/SafetySetting';
import AccountRecharge from '../AccountRecharge/AccountRecharge';
import RechargeRecord from '../RechargeRecord/RechargeRecord';
import ConsumeRecord from '../ConsumeRecord/ConsumeRecord';
import MenuDataFun from '../../config/MenuConfig';
import { ROLE_STUDENT } from '../../config/constant';
import DataBoard from '../DataBoard/DataBoard';
import MyDownload from '../MyDownload/MyDownload';
import Actve from '../Actve';
import BoardSetting from '../BoardSetting';
import EarlyWarning from '../EarlyWarning';

const { Sider, Content } = Layout;

class Admin extends Component {

    componentDidMount() {
        // console.log('Admin', this.props.loginData);
    }

    // 退出登录
    logout = () => {
        this.props.history.replace(baseConfig.routeUrl + `/login`);
    }

    render() {
        let isStuType = this.props.loginData.role === ROLE_STUDENT ? true : false;
        let initPath = MenuDataFun(this.props.loginData)
        let defaultPath = initPath[0].children ? initPath[0].children[0].key : initPath[0].key;

        if (isStuType) {
            defaultPath = '/createassess'
        }

        return (
            <Layout style={{ height: '100%' }}>
                <HeaderNav adminLogout={this.logout} />
                <Layout>
                    {
                        !isStuType && <Sider width={200} className="site-layout-background">
                            <LeftNav />
                        </Sider>
                    }
                    <Layout style={{ minWidth: '870px', padding: '0' }}>
                        {!isStuType && <Breadcrumber />}
                        <Content
                            className="site-layout-background"
                            style={{
                                padding: 0,
                                margin: 0,
                                paddingTop: 10,
                                marginLeft: 10,
                                minHeight: 280,
                                height: '100%',
                                overflowY: 'auto'
                            }}
                        >
                            <Switch>
                                {/* 用户管理 */}
                                <Route path={baseConfig.routeUrl + "/header"} component={HeaderNav} />
                                {/* 用户管理 */}
                                <Route path={baseConfig.routeUrl + "/usermanage"} component={UserManage} />
                                {/* 机构设置 */}
                                <Route path={baseConfig.routeUrl + "/organsetting"} component={OrganSetting} />
                                {/* 用户详情 */}
                                {/* <Route path={baseConfig.routeUrl + "/userdetail/:role/:num/:fname/:uname/:phone/:gender/:id"} component={UserDetail} /> */}
                                <Route path={baseConfig.routeUrl + "/userdetail"} component={UserDetail} />
                                {/* 创建测评 */}
                                <Route path={baseConfig.routeUrl + "/createevaluate"} component={CreateEvaluate} />
                                {/* 受测者管理 */}
                                <Route path={baseConfig.routeUrl + "/assessormanage"} component={AssessorManage} />
                                {/* 受测者详情 */}
                                <Route path={baseConfig.routeUrl + "/assessordetail"} component={AssessorDetail} />
                                {/* 创建测评 */}
                                <Route path={baseConfig.routeUrl + "/createassess"} component={CreateAssess} />
                                {/* 共享管理 */}
                                <Route path={baseConfig.routeUrl + "/sharemanage"} component={ShareManage} />
                                {/* 报告管理 */}
                                <Route path={baseConfig.routeUrl + "/reportmanage"} component={ReportManage} />
                                {/* 个人资料 */}
                                <Route path={baseConfig.routeUrl + "/personaldata"} component={PersonalData} />
                                {/* 安全设置 */}
                                <Route path={baseConfig.routeUrl + "/safetysetting"} component={SafetySetting} />
                                {/* 账户充值 */}
                                <Route path={baseConfig.routeUrl + "/accountrecharge"} component={AccountRecharge} />
                                {/* 充值记录 */}
                                <Route path={baseConfig.routeUrl + "/rechargerecord"} component={RechargeRecord} />
                                {/* 消费记录 */}
                                <Route path={baseConfig.routeUrl + "/consumerecord"} component={ConsumeRecord} />
                                {/* 数据看板 */}
                                <Route path={baseConfig.routeUrl + "/databoard"} component={DataBoard} />
                                {/* 我的下载 */}
                                <Route path={baseConfig.routeUrl + "/mydownload"} component={MyDownload} />
                                {/* 激活 */}
                                <Route path={baseConfig.routeUrl + "/active"} component={Actve} />
                                {/* 看板设置 */}
                                <Route path={baseConfig.routeUrl + "/boardsetting"} component={BoardSetting} />
                                {/* 预警列表 */}
                                <Route path={baseConfig.routeUrl + "/earlywarning"} component={EarlyWarning} />
                                {/* 默认显示 */}
                                <Redirect to={baseConfig.routeUrl + defaultPath} />
                            </Switch>
                        </Content>
                    </Layout>
                </Layout>
            </Layout>
        )
    }
}


const mapStateToProps = (state) => {
    return { loginData: state.login }
}

const mapDispatchToProps = (dispatch) => {
    return {
        add: (data) => { dispatch(adminTransfer(data)) }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Admin)
