import React, { Component } from 'react';
import { Layout, Input, Button, Modal, message } from 'antd';
import { connect } from 'react-redux';
import './ForgetPwd.less';
import axios from 'axios';
import baseConfig from '../../config/BaseConfig';

const { Header } = Layout;

class ForgetPwd extends Component {
    accountRef = React.createRef();
    newPwdRef = React.createRef();
    newPwd2Ref = React.createRef();

    state = {
        accountName: '',
        firstShow: 'block',
        // secondShow: 'block',
        secondShow: 'none',
        accountPhone: '',
        accountRes: '',
        smsModalVisible: false,
        smsBtnText: '发送验证码',
        smsBtnStatus: false,
        smsRes: '',
        newPwdText: '',
        newPwd2Text: '',
        codeInputValue: ''
    }

    // 检查账号
    checkAccount = () => {
        let tempaccount = this.accountRef.current.state.value;
        if (!tempaccount) {
            this.setState({ accountRes: '请您输入用户名/手机' });
            return false;
        }
        axios.get(baseConfig.httpUrl + '/api/reset-pass/check-account', { params: { uname: tempaccount } }).then(
            response => {
                if (response.data.res === 1) {
                    this.setState({
                        accountPhone: response.data.phone,
                        accountName: tempaccount,
                        accountRes: '',
                        smsModalVisible: true
                    })
                }
                else {
                    this.setState({ accountRes: response.data.msg })
                }
            }
        );
    }

    // 关闭验证码
    codeCancel = () => {
        this.setState({ smsModalVisible: false, smsRes: '', codeInputValue: '' });
    }

    // 发送验证码
    sendSms = () => {
        if (!this.state.accountPhone) {
            return false;
        }
        this.setState({ smsBtnText: '正在发送', smsBtnStatus: true });
        axios.post(baseConfig.httpUrl + '/api/security/sms-code', { phone: this.state.accountPhone }).then(
            response => {
                if (response.data.res === 1) {
                    this.msgCountDown();
                }
                else {
                    this.setState({ smsRes: response.data.msg })
                }
            }
        )
    }

    // 倒计时
    msgCountDown = () => {
        this.setState({ smsBtnText: '发送成功' });
        let countDownNum = 0;
        let intervalId = setInterval(() => {
            if ((59 - countDownNum) === 0) {
                this.setState({ smsBtnText: '发送验证码', smsBtnStatus: false });
                clearInterval(intervalId);
            }
            else {
                this.setState({ smsBtnText: '重新发送(' + (59 - countDownNum) + ')' });
                countDownNum += 1;
            }
        }, 1000);
    }

    // 验证短信验证码
    verifyCode = () => {
        let tempCode = this.state.codeInputValue;
        if (!tempCode) {
            this.setState({ smsRes: '请您输入短信验证码' });
            return false;
        }
        axios.post(baseConfig.httpUrl + '/api/reset-pass/validate-sms', { phone: this.state.accountPhone, code: tempCode }).then(
            response => {
                if (response.data.res === 1) {
                    this.setState({ smsModalVisible: false, smsRes: '', firstShow: 'none', secondShow: 'block', })
                }
                else {
                    this.setState({ smsRes: response.data.msg });
                }
            }
        );
    }

    // 
    codeChange = (e) => {
        this.setState({ codeInputValue: e.target.value });
    }

    // 重置密码
    resetPwd = () => {
        let tempPwd1 = this.newPwdRef.current.state.value;
        let tempPwd2 = this.newPwd2Ref.current.state.value;
        const regExp = new RegExp(/^[0-9A-Za-z]{6,32}$/, "g");

        if (!tempPwd1) {
            this.setState({ newPwdText: '请您输入新密码', newPwd2Text: '' });
        }
        else if (!tempPwd2) {
            this.setState({ newPwd2Text: '请您输入确认密码', newPwdText: '' });
        }
        else if (tempPwd1 !== tempPwd2) {
            this.setState({ newPwd2Text: '您输入的密码与确认密码不一致', newPwdText: '' });
        }
        else if (!regExp.test(tempPwd1)) {
            this.setState({ newPwdText: '输入密码不符合规则：密码由数字和英文组成，长度6-32位', newPwd2Text: '' });
        }
        else {
            this.setState({ newPwdText: '', newPwd2Text: '' });
            axios.post(baseConfig.httpUrl + '/api/reset-pass/change', { new_pass: tempPwd1 }).then(
                response => {
                    if (response.data.res === 1) {
                        message.success('密码修改成功');
                        this.props.history.replace(baseConfig.routeUrl + '/login');
                    }
                    else {
                        message.error(response.data.msg);
                    }
                }
            );
        }
    }

    // 登录页
    goLogin = () => {
        this.props.history.replace(baseConfig.routeUrl + '/login');
    }

    render() {
        return (
            <Layout style={{ height: '100%', backgroundColor: '#ffffff' }} className='ForgetPwd'>
                <Header className="ForgetPwd-header">
                    <div className="logo" />
                    <span className='ForgetPwd-header-title' onClick={this.goLogin}>脑云梯脑智综合评估系统</span>
                </Header>
                <div className="ForgetPwd-title">
                    <p className='ForgetPwd-title-p'>找回密码</p>
                </div>
                <div className='ForgetPwd-first' style={{ display: this.state.firstShow }}>
                    <p style={{ lineHeight: '36px' }}>请填写您需要找回的帐号</p>
                    <Input allowClear ref={this.accountRef} placeholder="请您输入用户名/手机" />
                    <p className='errorP' style={{ textAlign: 'center', margin: '10px 0' }}>{this.state.accountRes}</p>
                    <div style={{ textAlign: 'center' }}>
                        <Button type="primary" htmlType="submit" style={{ width: '100px' }} onClick={this.checkAccount}>下一步</Button>
                    </div>
                </div>

                <div className='ForgetPwd-second' style={{ display: this.state.secondShow }}>
                    <p style={{ lineHeight: '36px' }}>您正在找回的帐号是：{this.state.accountName}</p>
                    <div style={{ width: '500px' }}>
                        <div>
                            <p className='title'>新密码</p>
                            <Input.Password ref={this.newPwdRef} style={{ width: '300px', height: '36px' }} />
                        </div>
                        <p className='errorP' style={{ textAlign: 'center' }}>{this.state.newPwdText}</p>
                        <div>
                            <p className='title'>确认新密码</p>
                            <Input.Password ref={this.newPwd2Ref} style={{ width: '300px', height: '36px' }} />
                        </div>
                        <p className='errorP' style={{ textAlign: 'center' }}>{this.state.newPwd2Text}</p>
                        <div style={{ textAlign: 'center', marginTop: '10px' }}>
                            <Button type="primary" onClick={this.resetPwd} style={{ width: '100px' }}>确定</Button>
                        </div>
                    </div>
                </div>

                {/* 删除用户 */}
                <Modal
                    className='modals modals-noButton ForgetPwd-modals '
                    title="安全验证"
                    visible={this.state.smsModalVisible}
                    onCancel={this.codeCancel}
                    maskClosable={false}
                >
                    <p>您的帐号可能存在安全风险，为了确保为您本人操作，请先进行安全验证。</p>
                    <p style={{ fontWeight: 'bold' }}>验证手机号码</p>
                    <p className='accountPhone'>{this.state.accountPhone.substr(0, 3)}****{this.state.accountPhone.substr(-4)}</p>
                    <div style={{ overflow: 'hidden' }}>
                        <Input className='smsInput' placeholder="请输入短信验证码" value={this.state.codeInputValue} onChange={this.codeChange} />
                        <Button className='smsBtn' onClick={this.sendSms} disabled={this.state.smsBtnStatus} >{this.state.smsBtnText}</Button>
                    </div>
                    <p className='errorP'>{this.state.smsRes}</p>
                    <div style={{ textAlign: 'center' }}>
                        <Button type="primary" style={{ width: '100px' }} onClick={this.verifyCode}>确定</Button>
                    </div>
                </Modal>
            </Layout>
        )
    }
}

// 容器组件
const mapStateToProps = (state) => {
    return {}
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgetPwd);